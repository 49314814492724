import React, { ChangeEvent } from "react";
import { FormattedMessage, WrappedComponentProps, injectIntl } from "react-intl";
import { WithRouterProps, withRouter } from "../../../../router";
import { connect } from "react-redux";
import { Box, Button, Container, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, InputLabel, MenuItem, OutlinedInput, Paper, Select, SelectChangeEvent, Switch, TextField } from "@mui/material";
import http from "../../../../services/HttpService";
import Toaster from "../../../controls/toaster/Toaster";
import { ValidationError } from "../../../../models/ValidationErrorModel";
import { CountryModel } from "../../../../models/CountryModels";
import { FlowModel } from "../../../../models/FlowModels";
import { FlowRuleTrigger, FlowStepModel } from "../../../../models/FlowStepModels";
import { DataGrid, GridColDef, GridRowsProp, GridValidRowModel } from '@mui/x-data-grid';
import DraggableDataGrid from "../../../controls/draggable-data-grid/DraggableDataGrid";
import { DropResult } from "react-beautiful-dnd";
import { EmailTemplateSimpleModel } from "../../../../models/EmailTemplateModels";

interface FlowProps extends WrappedComponentProps, WithRouterProps {

}

interface FlowState {
	id?: string;
	name: string;
	nameIsValid?: boolean;
	nameErrorMessage?: string;
    description: string;
	descriptionIsValid?: boolean;
	descriptionErrorMessage?: string;
	country?: string;
	countryIsValid?: boolean;
	countryErrorMessage?: string;
	isActive: boolean;
	allCountries?: Array<CountryModel>;
	flowSteps: Array<FlowStepModel>;
	flowStepNameIsValid?: boolean;
	flowStepNameErrorMessage?: string;
	flowStepEmailTemplateIsValid?: boolean;
	flowStepEmailTemplateErrorMessage?: string;
	flowStepIntervalIsValid?: boolean;
	flowStepIntervalErrorMessage?: string;
	rowEditNumber: number;
	allEmailTemplates?: Array<EmailTemplateSimpleModel>;
	flowStep?: FlowStepModel;
}

class Flow extends React.Component<FlowProps, FlowState> {
	constructor(props: FlowProps) {
		super(props);

		this.state = {
			name: "",
			description: "",
			flowSteps: [],
			isActive: true,
			rowEditNumber: -1
		}

		this.getCountries();
		this.getEmailTemplates();

		if(this.props.params?.id){
			this.getData();
		}
	}

	columns: GridColDef[] = [
		{ field: 'name', headerName: 'Name' },
		{ field: 'trigger', headerName: 'Trigger' },
		{ field: 'interval', headerName: 'Interval' },
		{ field: 'emailTemplate', headerName: 'Email Template' },
		{ field: 'isActive', headerName: 'Is Active' }
	];

	rowMap(item: FlowStepModel, index: number) {
		if(this.state.rowEditNumber !== index){
			return {
				id: item.id, 
				name: item.name, 
				trigger: item.trigger === FlowRuleTrigger.TimeTrigger ? "Time Trigger" : "", 
				interval: item.interval, 
				emailTemplate: item.emailTemplate, 
				isActive: item.isActive ? "Yes" : "No"
			};
		}
		else {
			return {
				id: item.id, 
				name: 
					<FormControl
						sx={{
							width: "100%",
							pr: { xs: 2}
						}}>
						<TextField
							required
							fullWidth
							id={item.id}
							name={"flow-step-name["+index+"]"}
							value={item.name}
							inputProps={{
								form: {
								autoComplete: 'off',
								},
							}}
							label={this.props.intl.formatMessage({ id: "labels_name" })}
							error={this.state.flowStepNameIsValid === false}
							onChange={this.onFieldChangeAsync.bind(this)}
							onBlur={(event) => this.onFieldChangeAsync(event as ChangeEvent<HTMLInputElement>)} 
							helperText={this.state.flowStepNameErrorMessage} />
						</FormControl>, 
				trigger: 
					<FormControl
						sx={{
							pr: { xs: 2, sm: 2 },
							minWidth: "100%"
						}}>
						<InputLabel required id="select-trigger-label">Trigger</InputLabel>
						<Select
							labelId="select-trigger-label"
							input={<OutlinedInput id="select-trigger" label="Trigger" />}
							value={item.trigger}
							label="Trigger"
							name={item.id}
							onChange={this.handleTriggerSelectChange.bind(this)}>
							<MenuItem value={1}>Time Trigger</MenuItem>
						</Select>
					</FormControl>, 
				interval: 
					<FormControl
						sx={{
							width: "100%",
							pr: { xs: 2}
						}}>
						<TextField
							required
							fullWidth
							name={"flow-step-interval["+item.id+"]"}
							value={item.interval}
							id={item.id}
							inputProps={{
								form: {
								autoComplete: 'off',
								},
							}}
							placeholder="d:hh:mm"
							label={this.props.intl.formatMessage({ id: "labels_interval" })}
							error={this.state.flowStepIntervalIsValid === false}
							onChange={this.onFieldChangeAsync.bind(this)}
							onBlur={(event) => this.onFieldChangeAsync(event as ChangeEvent<HTMLInputElement>)} 
							helperText={this.state.flowStepIntervalErrorMessage} />
					</FormControl>, 
				emailTemplate: 
					<FormControl
						error={this.state.flowStepEmailTemplateIsValid === false}
						sx={{
							pr: { xs: 2, sm: 2 },
							minWidth: "100%"
						}}>
						<InputLabel required id="select-email-template-label">Email Template</InputLabel>
						<Select
							labelId="select-email-template-label"
							input={<OutlinedInput id="select-email-template" label="Email Template" />}
							value={item.emailTemplateId ?? ''}
							label="Email Template"
							name={item.id}
							onChange={this.handleEmailTemplateSelectChange.bind(this)}>
							{this.state?.allEmailTemplates?.map((item) => {
								return (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>);
							})}
						</Select>
						<FormHelperText error={this.state.flowStepEmailTemplateIsValid === false}>{this.state.flowStepEmailTemplateErrorMessage}</FormHelperText>
					</FormControl>, 
				isActive: 
					<FormControl
						sx={{
							width: "100%",
							pr: { xs: 2}
						}}>
						<Switch 
							id={item.id}
							name={"flow-step-is-active["+item.id+"]"} 
							onChange={this.onFieldChangeAsync.bind(this)} 
							checked={item.isActive } />
					</FormControl>
			};
		}
	}

	handleEmailTemplateSelectChange = (event: SelectChangeEvent<string>) => {
		const {value, name} = event.target;
  
		let isValid = true;
		let errorMessage = "";

		if(value === "" || value === undefined){
			isValid = false;
			errorMessage = "Email Template is required";
		}
		
		this.setState({
			flowSteps: 
				this.state.flowSteps.map((item, index) => {
					if(item.id === name){
						item.emailTemplateId = value;
						item.emailTemplate = this.state.allEmailTemplates?.find((item) => item.id === value)?.name ?? "";
					}

					return item;
				}),
			flowStepEmailTemplateIsValid: isValid,
			flowStepEmailTemplateErrorMessage: errorMessage
		});
	};

	handleTriggerSelectChange = (event: SelectChangeEvent<FlowRuleTrigger>) => {
		const {value, name} = event.target;
  
		this.setState({
			flowSteps: 
				this.state.flowSteps.map((item, index) => {
					if(item.id === name){
						item.trigger = value as FlowRuleTrigger;
					}

					return item;
				})
		});
	};

	async onFieldChangeAsync(event: ChangeEvent<HTMLInputElement>) {
		const { id, name, value } = event.target;

		if (name === "name") {
			const isValid = value.length >= 1;

			await this.setState({
				name: value,
				nameIsValid: isValid,
				nameErrorMessage: !isValid ? "Name is required" : ""
			});
		}

		if (name === "description") {
			await this.setState({
				description: value,
				descriptionIsValid: true,
				descriptionErrorMessage: ""
			});
		}

		if(name === "isactive"){
			await this.setState({
				isActive: !this.state.isActive
			});
		}

		if(name.includes("flow-step-name")){
			const isValid = value.length >= 1;

			this.setState({
				flowSteps: 
					this.state.flowSteps.map((item, index) => {
						if(item.id === id){
							item.name = value;
						}

						return item;
					}),
				flowStepNameIsValid: isValid,
				flowStepNameErrorMessage: !isValid ? "Name is required" : ""
			});
		}

		if(name.includes("flow-step-is-active")){
			this.setState({
				flowSteps: 
					this.state.flowSteps.map((item, index) => {
						if(item.id === id){
							item.isActive = !item.isActive;
						}

						return item;
					})
			});
		}

		if(name.includes("flow-step-interval")){
			const regex = /^(\d+):([0-1]?\d|2[0-3]):([0-5]?\d)$/;
            const isValid = regex.test(value);

			this.setState({
				flowSteps: 
					this.state.flowSteps.map((item, index) => {
						if(item.id === id){
							item.interval = value;
						}

						return item;
					}),
				flowStepIntervalIsValid: isValid,
				flowStepIntervalErrorMessage: !isValid ? "Interval is required and should match d:hh:mm format" : ""
			});
		}
	}

	handleSelectChange = (event: SelectChangeEvent<typeof this.state.country>) => {
		const {
			target: { value },
		  } = event;
  
		  this.setState({
			  country: value
		  });
	  };

	handleCancel(event: React.MouseEvent<unknown>) {
		this.props.navigate("/admin/flows");
	}

	handleSaveChanges(event: React.MouseEvent<unknown>) {
		this.saveChanges();
	}

	onDragEnd = (result: DropResult) => {
		if (!result.destination) return;

		const reorderedRows = Array.from(this.state.flowSteps);
		const [removed] = reorderedRows.splice(result.source.index, 1);
		reorderedRows.splice(result.destination.index, 0, removed);
		reorderedRows.map((item, index) =>{
			item.order = index
		});

		this.setState({
			flowSteps: reorderedRows
		});
	};

	handleAdd = (event: unknown) => {
		const flowStep = {
			id: "new-"+this.state.flowSteps.length, 
			name: "", 
			trigger: FlowRuleTrigger.TimeTrigger, 
			interval: "", 
			emailTemplateId: "", 
			emailTemplate: "", 
			order: this.state.flowSteps.length, 
			isActive: true
		};

		this.setState({
			flowSteps: [...this.state.flowSteps, flowStep],
			flowStepNameIsValid: false,
			flowStepNameErrorMessage: "Name is required",
			flowStepEmailTemplateIsValid: false,
			flowStepEmailTemplateErrorMessage: "Email Template is required",
			flowStepIntervalIsValid: false,
			flowStepIntervalErrorMessage: "Interval is required",
			rowEditNumber: this.state.flowSteps.length
		})
	};

	handleEdit = (index: number) => {
		const flowStep = this.state.flowSteps.find((item, indx) => indx === index);
        this.setState({
            rowEditNumber: index,
			flowStep: flowStep ? {id: flowStep.id, 
				name: flowStep.name, 
				trigger: flowStep.trigger, 
				interval: flowStep.interval, 
				emailTemplateId: flowStep.emailTemplateId, 
				emailTemplate: flowStep.emailTemplate, 
				order: flowStep.order, 
				isActive: flowStep.isActive} : flowStep,
			flowStepNameIsValid: true,
			flowStepNameErrorMessage: "",
			flowStepEmailTemplateIsValid: true,
			flowStepEmailTemplateErrorMessage: "",
			flowStepIntervalIsValid: true,
			flowStepIntervalErrorMessage: ""
        });
    }

    handleSave = (row: GridValidRowModel) => {
		if(this.state.flowStepEmailTemplateIsValid && 
			this.state.flowStepIntervalIsValid && 
			this.state.flowStepNameIsValid){
			this.setState({
				rowEditNumber: -1,
				flowStep: undefined
			});
		}
    }

    handleDiscard = (index: number) => {
        this.setState({
            rowEditNumber: -1,
			flowSteps: 
				this.state.flowStep ? this.state.flowSteps.map((item, indx) => {
					if(indx === index && this.state.flowStep){
						return this.state.flowStep
					}
					
					return item;
				}) : this.state.flowSteps.filter((item, indx) => indx != index),
			flowStep: undefined
        });
    }

    handleDelete = (index: number) => {
        this.setState({
            rowEditNumber: -1,
			flowSteps: this.state.flowSteps.filter((item, indx) => {
				return index !== indx;
			})
        });
    }

	saveChanges(){
		const params = {
			name: this.state.name,
			description: this.state.description,
			countryId: this.state.country === "" ? undefined : this.state.country,
			isActive: this.state.isActive,
			flowSteps: this.state.flowSteps.map((item) => {
				return {
					id: item.id.includes("new") ? undefined : item.id, 
					name: item.name, 
					trigger: item.trigger, 
					interval: item.interval, 
					emailTemplateId: item.emailTemplateId, 
					order: item.order, 
					isActive: item.isActive
				};
			})
		  };

		if(this.state.id){
			http.put(`/flows/${this.state.id}`, params)
			.then((response) => {
				this.props.navigate("/admin/flows");
			}).catch((error) => {
				let errors = error.data?.errors as Array<ValidationError>;
				if(errors){
					errors?.map(item => {
						switch(item.property.toLocaleLowerCase()){
							case "name": {
								this.setState({
									nameIsValid: false,
									nameErrorMessage: item.message
								});
								break;
							}
							case "description": {
								this.setState({
									descriptionIsValid: false,
									descriptionErrorMessage: item.message
								});
								break;
							}
							case "countryid": {
								this.setState({
									countryIsValid: false,
									countryErrorMessage: item.message
								});
								break;
							}
							default: {
								Toaster.Show("error", "Error", item?.message);
								break;
							}
						}
					})
				}
				else{
					Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
				}
			});
		}
		else{
			http.post(`/flows`, params)
				.then((response) => {
					this.props.navigate("/admin/flows");
				}).catch((error) => {
					let errors = error.data?.errors as Array<ValidationError>;
					if(errors){
						errors?.map(item => {
							switch(item.property.toLocaleLowerCase()){
								case "name": {
									this.setState({
										nameIsValid: false,
										nameErrorMessage: item.message
									});
									break;
								}
								case "description": {
									this.setState({
										descriptionIsValid: false,
										descriptionErrorMessage: item.message
									});
									break;
								}
								case "countryid": {
									this.setState({
										countryIsValid: false,
										countryErrorMessage: item.message
									});
									break;
								}
								default: {
									Toaster.Show("error", "Error", item?.message);
									break;
								}
							}
						})
					}
					else{
						Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
					}
				});
		}
	}

	getCountries() {
		http.get(`/countries`)
		.then((response) => {
			let responseData = response.data as Array<CountryModel>;
			this.setState({
				allCountries: responseData
			});
		})
		.catch((error) => {
			Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
		});
	}

	getEmailTemplates() {
		http.get(`/emailTemplates/getAll`)
		.then((response) => {
			let responseData = response.data as Array<EmailTemplateSimpleModel>;
			this.setState({
				allEmailTemplates: responseData
			});
		})
		.catch((error) => {
			Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
		});
	}

	getData(){
		http.get(`/flows/${this.props.params?.id}`)
			.then((response) => {
				let responseData = response.data as FlowModel;

				this.setState({
					id: responseData.id,
					name: responseData.name,
					description: responseData.description,
					country: responseData.countryId ?? "",
					isActive: responseData.isActive,
					flowSteps: responseData.flowSteps
				});
			}).catch((error) => {
				Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
			});
	}

	render(): React.ReactNode {
		return(
			<Paper>
				<form>
					<FormGroup 
						sx={{
							pt: { xs: 3 },
							//pb: { xs: 3 },
							pr: { xs: 2 },
							pl: { xs: 2 }
					}}>
						<Grid container>
							<Grid item xs={4}>
								<FormControl
									sx={{
										width: "100%",
										pr: { xs: 2}
									}}>
									<TextField
										required
										fullWidth
										name="name"
										value={this.state.name}
										inputProps={{
											form: {
											  autoComplete: 'off',
											},
										  }}
										label={this.props.intl.formatMessage({ id: "labels_name" })}
										error={this.state.nameIsValid === false}
										onChange={this.onFieldChangeAsync.bind(this)}
										onBlur={(event) => this.onFieldChangeAsync(event as ChangeEvent<HTMLInputElement>)} 
										helperText={this.state.nameErrorMessage} />
								</FormControl>
							</Grid>
							<Grid item xs={4}>
								<FormControl
									sx={{
										width: "100%",
										pr: { xs: 2}
									}}>
									<TextField
										fullWidth
										name="description"
										value={this.state.description}
										inputProps={{
											form: {
											  autoComplete: 'off',
											},
										  }}
										label={this.props.intl.formatMessage({ id: "labels_description" })}
										error={this.state.descriptionIsValid === false}
										onChange={this.onFieldChangeAsync.bind(this)}
										onBlur={(event) => this.onFieldChangeAsync(event as ChangeEvent<HTMLInputElement>)} 
										helperText={this.state.descriptionErrorMessage} />
								</FormControl>
							</Grid>
                            <Grid item xs={4} >
								<FormControl
									error={this.state.countryIsValid === false}
									sx={{
										//pr: { xs: 3, sm: 3 },
										minWidth: "100%"
									}}>
									<InputLabel id="select-country-label">Country</InputLabel>
									<Select
										labelId="select-country-label"
										input={<OutlinedInput id="select-country" label="Country" />}
										value={this.state.country ?? ''}
										label="Country"
										onChange={this.handleSelectChange}>
										<MenuItem><em>None</em></MenuItem>
										{this.state?.allCountries?.map((item) => {
											return (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>);
										})}
									</Select>
									<FormHelperText error={this.state.countryIsValid === false}>{this.state.countryErrorMessage}</FormHelperText>
								</FormControl>
							</Grid>
						</Grid>
					</FormGroup>
					<FormGroup 
						sx={{
							pt: { xs: 2 },
							//pb: { xs: 3 },
							pr: { xs: 2 },
							pl: { xs: 2 }
					}}>
						<Grid container>
							<Grid item xs={4}>
								<FormControl
									sx={{
										width: "100%",
										pr: { xs: 2}
									}}>
									<FormControlLabel 
										control={
											<Switch 
												name="isactive" 
												onChange={this.onFieldChangeAsync.bind(this)} 
												checked={this.state.isActive } />} 
										label="Is Active?" />
								</FormControl>
							</Grid>
							<Grid item xs={4} />
							<Grid item xs={4} />
						</Grid>
					</FormGroup>
					<FormGroup
						sx={{
							pt: { xs: 2 },
							pb: { xs: 3 },
							pr: { xs: 2 },
							pl: { xs: 2 }
						}}>
						<DraggableDataGrid
							rows={this.state.flowSteps.map((item, index) => this.rowMap(item, index)) ?? [] as Array<GridValidRowModel>}
							columns={this.columns}
							rowEditNumber={this.state.rowEditNumber}
							onDragEnd={this.onDragEnd}
							handleAdd={this.handleAdd}
							handleDelete={this.handleDelete}
							handleDiscard={this.handleDiscard}
							handleEdit={this.handleEdit}
							handleSave={this.handleSave} />
					</FormGroup>
					<FormGroup 
						sx={{
							pt: { xs: 2 },
							pb: { xs: 3 },
							//pr: { xs: 2 },
							//pl: { xs: 2 }
						}}>
						<Grid container>
							<Grid item lg={9} md={8} sm={7} xs={6} />
							<Grid item lg={3} md={4} sm={5} xs={6} >
								<Box sx={{display: 'flex', alignItems: 'center', mr: 2}}>
									<Button
										sx={{
											px: 2,
											mr: 1,
											ml: "auto"
										}}
										onClick={this.handleCancel.bind(this)}
										variant="outlined">
										Cancel
									</Button>
									<Button
										sx={{
											//px: 2,
										}}
										disabled={this.state.rowEditNumber >= 0}
										onClick={this.handleSaveChanges.bind(this)}
										variant="contained">
										Save
									</Button>
								</Box>
							</Grid>
						</Grid>
					</FormGroup>
				</form>
			</Paper>
		);
	}
}

const mapDispatchToProps = (dispatch: any) => {
	return {
	};
};

export default withRouter(injectIntl(connect(null, mapDispatchToProps)(Flow)));