import { Palette } from "@mui/material/styles/createPalette";

export const AppThemePalette = {
	background: {
		default: "rgba(243, 246, 249, 0.6)"
	},
	primary: {
		main: "#4F4A7B",
		dark: "#2E2B46",
		contrastText: "#f1f1f1"
	},
	secondary: {
		main: "#D88D1E",
		contrastText: "#f1f1f1"
	},
	error: {
		main: "#F05656"
	},
	success: {
		main: "#20CA7B"
	},
	warning: {
		main: "#FFA000"
	},
	info: {
		main: "#19a6ea"
	},
	text: {
		primary: "#2E2B46",
		secondary: '#727274'
	}
} as Palette;