import * as actionTypes from "../actions/ActionTypes";
import LocalStorageService from "../../services/LocalStorageService";

const initialState = {
	locale: "en-us",
	appVersion: '',
	globalLoaderVisible: false,
}

const changeLocale = (state: any, locale: string) => {
	LocalStorageService.setLocalization(locale);
	state.locale = locale;

	return state;
}

const reducer = (state = initialState, action: any) => {
	switch (action.type) {
		case actionTypes.CHANGE_LOCALE:
			let changedState = changeLocale(state, action.data);
			LocalStorageService.setLocalization(changedState.locale);

			return {
				...changedState
			}
		case actionTypes.APP_VERSION_UPDATED:
			return {
				...state,
				appVersion: action.data
			};
		case actionTypes.SHOW_GLOBAL_LOADER:
			return {
				...state,
				globalLoaderVisible: true
			};
		case actionTypes.HIDE_GLOBAL_LOADER:
			return {
				...state,
				globalLoaderVisible: false
			};
		default:
			return state;
	}
}

export default reducer;