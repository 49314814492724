import { Components } from "@mui/material"
import { Theme } from "@mui/system"

export default {
	MuiAlert: {
		styleOverrides: {
			root: {
				zIndex: 1000
			},
			filled: {
				boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.22)',
				"&.MuiAlert-filledInfo": {
					color: "white"
				},
				"&.MuiAlert-filledWarning": {
					color: "white"
				},
				"&.MuiAlert-filledError": {
					color: "white"
				},
				"&.MuiAlert-filledSuccess": {
					color: "white"
				},
			},
			action: {
				display: "flex",
				alignItems: "center"
			},
			icon: {
				display: "flex",
				marginRight: "1.5rem",
				alignItems: "center",
				fontSize: "1.7rem"
			}
		}
	}
} as Components<Omit<Theme, "components">>