import { ButtonBase, ButtonBaseProps, SxProps } from "@mui/material";
import React, { PropsWithChildren } from "react";
import * as authActions from "../../../store/actions/AuthActions";
import * as accountActions from "../../../store/actions/AccountActions";
import { ApplicationUser } from "../../../models/AuthModels";
import { connect } from "react-redux";
import { withRouter, WithRouterProps } from "../../../router";

import { Theme } from '@mui/material/styles';

interface MyProfileProps extends PropsWithChildren, WithRouterProps {
	sx: SxProps<Theme> | undefined,
    isUserLoggedIn?: boolean,
	currentUser?: ApplicationUser
}

interface MyProfileState { }

class MyProfile extends React.Component<MyProfileProps, MyProfileState> {
	constructor(props: MyProfileProps) {
		super(props);
	}

	myProfile = (event: any) => {
		if (event) {
			event.preventDefault();
		}

		this.props.navigate(`/admin/users/${this.props.currentUser?.id}`);
	};

	render(): React.ReactNode {
		return (
			<ButtonBase
				sx={this.props.sx}
				onClick={this.myProfile.bind(this)}>
				{this.props.children}
			</ButtonBase>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		isUserLoggedIn: state.auth.isUserLoggedIn,
		currentUser: state.account.currentUser
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		logout: () =>
			dispatch(authActions.UserLoggedOut()),
		setCurrentUser: (user: ApplicationUser) =>
			dispatch(accountActions.SetCurrentUser(user))
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyProfile));