import { Button, ButtonProps, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom/client';
import AppTheme from '../../../styles/AppTheme';


type DialogSeverity = 'normal' | 'warning' | 'danger';

interface ConfirmDialogButton {
	text: string,
	props?: ButtonProps
}

export interface ConfirmDialogCancelButton extends ConfirmDialogButton {
	position: 'left' | 'right'
}

interface ConfirmDialogProps {
	severity: DialogSeverity,
	title?: string,
	message: string,
	buttons?: Array<ConfirmDialogButton>,
	cancelButton: ConfirmDialogCancelButton
}

interface ConfirmDialogState {
	isOpen: boolean,
	titleColor: string
}

const defaultCancelButton = {
	position: "left",
	text: "Cancel",
	props: {
		variant: "outlined"
	}
} as ConfirmDialogCancelButton;

let confirmDialogRoot = undefined as ReactDOM.Root | undefined;

class ConfirmDialog extends React.Component<ConfirmDialogProps, ConfirmDialogState> {

	constructor(props: ConfirmDialogProps) {
		super(props);

		this.state = {
			isOpen: true,
			titleColor: ""
		};
	}

	static Show(
		severity: DialogSeverity,
		title: string,
		message: string,
		buttons: Array<ConfirmDialogButton> | undefined = undefined,
		cancelButton: ConfirmDialogCancelButton = defaultCancelButton) {

		const containerElement = document.createElement('div');
		containerElement.id = "confirm-modal-wrapper";
		document.body.appendChild(containerElement);

		confirmDialogRoot = ReactDOM.createRoot(containerElement as HTMLElement);

		confirmDialogRoot.render(
			<AppTheme>
				<ConfirmDialog
					title={title}
					message={message}
					severity={severity}
					buttons={buttons}
					cancelButton={cancelButton} />
			</AppTheme>
		);
	}

	componentDidMount(): void {
		let titleColor = AppTheme.CurrentTheme.palette.primary.main;
		if (this.props.severity === "danger") {
			titleColor = AppTheme.CurrentTheme.palette.error.main;
		} else if (this.props.severity === "warning") {
			titleColor = AppTheme.CurrentTheme.palette.warning.main;
		}

		this.setState({
			titleColor: titleColor
		});
	}

	closeDialog() {
		this.setState({
			isOpen: false
		});

		setTimeout(() => {
			let wrapper = document.getElementById("confirm-modal-wrapper");
			if (wrapper) {
				wrapper.remove();
			}

			confirmDialogRoot?.unmount();
		}, 500);
	}

	render() {
		return (
			<Dialog
				open={this.state.isOpen}>
				{
					this.props.title &&
					<DialogTitle
						sx={{
							color: this.state.titleColor
						}}>
						{this.props.title}
					</DialogTitle>
				}
				<DialogContent>
					{this.props.message}
				</DialogContent>
				<DialogActions>
					{
						this.props.cancelButton.position === "left" &&
						<Button
							{...this.props.cancelButton.props}
							onClick={(e) => {
								if (this.props.cancelButton.props?.onClick) {
									this.props.cancelButton.props.onClick(e);
								}
								this.closeDialog();
							}}>
							{
								this.props.cancelButton.text
							}
						</Button>
					}

					{
						this.props.buttons?.map((button, i) =>
							<Button
								{...button.props}
								key={i}
								onClick={(e) => {
									if (button.props?.onClick) {
										button.props.onClick(e);
									}
									this.closeDialog();
								}}>
								{button.text}
							</Button>
						)
					}

					{
						this.props.cancelButton.position === "right" &&
						<Button
							{...this.props.cancelButton.props}
							onClick={(e) => {
								if (this.props.cancelButton.props?.onClick) {
									this.props.cancelButton.props.onClick(e);
								}
								this.closeDialog();
							}}
						>
							{
								this.props.cancelButton.text
							}
						</Button>
					}
				</DialogActions>
			</Dialog>
		);
	}
}

export default ConfirmDialog;