import { Box, Button, ButtonProps, Container, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom/client';
import CloseIcon from '@mui/icons-material/Close';
import FootballBallsImg from '../../../assets/images/football_balls.svg';
import LandingTheme from '../../../styles/LandingTheme';


interface UnsubscribedDialogButton {
}

export interface UnsubscribedDialogCancelButton extends UnsubscribedDialogButton {
}

interface UnsubscribedDialogProps {
}

interface UnsubscribedDialogState {
	isOpen: boolean
}

let confirmDialogRoot = undefined as ReactDOM.Root | undefined;

class UnsubscribedDialog extends React.Component<UnsubscribedDialogProps, UnsubscribedDialogState> {

	constructor(props: UnsubscribedDialogProps) {
		super(props);

		this.state = {
			isOpen: true
		};
	}

	static Show() {
		const containerElement = document.createElement('div');
		containerElement.id = "confirm-modal-wrapper";
		document.body.appendChild(containerElement);

		confirmDialogRoot = ReactDOM.createRoot(containerElement as HTMLElement);

		confirmDialogRoot.render(
			<LandingTheme>
				<UnsubscribedDialog />
			</LandingTheme>
		);
	}

	componentDidMount(): void {
		let titleColor = LandingTheme.CurrentTheme.palette.primary.main;

		this.setState({
			//titleColor: titleColor
		});
	}

	closeDialog() {
		this.setState({
			isOpen: false
		});

		setTimeout(() => {
			let wrapper = document.getElementById("confirm-modal-wrapper");
			if (wrapper) {
				wrapper.remove();
			}

			confirmDialogRoot?.unmount();
		}, 500);
	}

	render() {
		return (
			<Dialog
                sx={{
                    "& .MuiDialog-paper": {
                        borderRadius: 5,
                    },
                }}
                maxWidth={"xl"}
				open={this.state.isOpen}>
                <DialogTitle
                    textAlign="center"
                    sx={{
                        mt: 5,
                        ml: "auto",
                        mr: "auto",
                        color: "#000000"
                    }}>
                        <Box maxWidth={{xs: "299px", sm: "571px"}} sx={{ml: "auto", mr: "auto"}}>
                            <img width={"100%"} src={FootballBallsImg} />
                        </Box>
                        <Typography maxWidth={{xs: "400px", sm: "948px"}} sx={{mt: 5, fontWeight: 900, fontSize: {md: "55px", xs: "28px"}, lineHeight: 1.2}}>
                            We’re sorry to hear you unsubscribed from our newsletter 😔
                        </Typography>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => this.closeDialog()}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    >
                    <CloseIcon sx={{color: "#000000"}} fontSize='large' />
                </IconButton>
				<DialogContent
                    sx={{
                        ml: "auto",
                        mr: "auto",
                        mb: 2,
                        color: "#000000",
                        textAlign: "center"
                    }}>
                    <Typography sx={{fontWeight: 300, fontSize: "16px", lineHeight: 1.2}}>
                        We’d be happy to see you join us once again, if you feel like receiving the best offers 🎁
                    </Typography>
                    {/* <Typography sx={{fontWeight: 300, fontSize: "16px", lineHeight: 1.2}}>
                        Don’t miss out on JOINING our Telegram group through which you can be updated even faster on relevant information and predictions.
                    </Typography>
                    <Typography sx={{fontWeight: 300, fontSize: "16px", lineHeight: 1.2}}>
                        Welcome once again, and let’s get started!
                    </Typography>
                    <Typography fontSize={"16px"} fontWeight={"fontWeightLight"} sx={{m: "0 auto", mt: 6, lineHeight: 1.2}}>
                        *You can opt-out from your subscription at any time
                    </Typography> */}
				</DialogContent>
			</Dialog>
		);
	}
}

export default UnsubscribedDialog;