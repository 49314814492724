import React from "react";
import { Box, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

class PageNotFound extends React.Component {
	render(){ 
		return (
			<Box
				display={'flex'}
				alignItems={'center'}
				flexDirection={'column'}>
				<Typography component={'h2'}>
					404
				</Typography>
				<Typography component={'span'}>
					<FormattedMessage id="labels_page_not_found_text" />
				</Typography>
			</Box>
		);
	}
}

export default PageNotFound;