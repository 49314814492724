import React, { MouseEventHandler, useState } from 'react';
import { DataGrid, GridRowsProp, GridColDef, GridValidRowModel } from '@mui/x-data-grid';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import Paper from '@mui/material/Paper';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Box, Button, Container, IconButton, Stack, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { green, red } from '@mui/material/colors';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

// const initialRows: GridRowsProp = [
//   { id: 1, col1: 'Hello', col2: 'World', col3: 'aha', col4: 'banana', col5: 'apple' },
//   { id: 2, col1: 'DataGridPro', col2: 'is Awesome', col3: 'aha', col4: 'banana', col5: 'apple' },
//   { id: 3, col1: 'Material-UI', col2: 'is Amazing', col3: 'aha', col4: 'banana', col5: 'apple' },
// ];

// const columns: GridColDef[] = [
//   { field: 'col1', headerName: 'Column 1' },
//   { field: 'col2', headerName: 'Column 2' },
//   { field: 'col3', headerName: 'Column 3' },
//   { field: 'col4', headerName: 'Column 4' },
//   { field: 'col5', headerName: 'Column 5' },
//   { field: 'col6', headerName: 'Column 6' },
// ];

interface DraggableDataGridProps {
	rows: Array<GridValidRowModel>,
    columns: Array<GridColDef>,
    rowEditNumber: number,
    onDragEnd: {(result: DropResult) : void},
    handleAdd: {(event: unknown) : void},
    handleEdit: {(index: number) : void},
    handleSave: {(row: GridValidRowModel) : void},
    handleDiscard: {(index: number) : void},
    handleDelete: {(index: number): void }
}

interface DraggableDataGridState {
}

class DraggableDataGrid extends React.Component<DraggableDataGridProps, DraggableDataGridState> {
	constructor(props: DraggableDataGridProps) {
		super(props);
	}

    render(): React.ReactNode {
        return(
        <>
            <Box sx={{border: '1px solid #ccc'}}>
                <Box sx={{p: 1}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Typography sx={{fontStyle: "italic", fontWeight: 400 }}>Flow steps: </Typography>
                        <Button disabled={this.props.rowEditNumber !== -1} variant="outlined" size='small' sx={{mb: 1, ml: "auto"}} onClick={this.props.handleAdd.bind(this)}>
                            <AddIcon />
                            Add
                        </Button>
                    </Box>
                    <DragDropContext onDragEnd={this.props.onDragEnd.bind(this)}>
                        <Droppable droppableId="droppable" direction="vertical">
                            {(provided) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                {this.props.columns && this.props.columns.length > 0 && 
                                <div
                                    style={{
                                        border: '1px solid #ccc',
                                    }}>
                                
                                <Paper elevation={0}>
                                    <div style={{ display: 'flex', padding: 16, fontWeight: 500, fontSize: '0.875rem' }}>
                                        <div style={{width:"15%", paddingRight: 16}}></div>
                                        {this.props.columns.map((column) => (
                                            <div key={column.field} style={{ width: '100%' }}>
                                            {column.headerName}
                                            </div>
                                        ))}
                                        <div style={{width:"15%", paddingRight: 16, minWidth: "84px"}}></div>
                                    </div>
                                </Paper>
                                </div>
                                }
                                {this.props.rows.map((row, index) => (
                                <Draggable isDragDisabled={this.props.rowEditNumber !== -1} key={row.id} draggableId={row.id.toString()} index={index}>
                                    {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                        ...provided.draggableProps.style,
                                        border: '1px solid #ccc',
                                        }}
                                    >
                                        <Paper elevation={0}>
                                            
                                        <div style={{ display: 'flex', padding: 16, }}>
                                            <div style={{width:"15%", paddingRight: 16}}>
                                                <DragIndicatorIcon />
                                            </div>
                                            {this.props.columns.map((column) => (
                                            <div key={column.field} style={{ width: '100%', fontSize: '0.875rem' }}>
                                                {row[column.field]}
                                            </div>
                                            ))}
                                            <div style={{width:"15%", paddingRight: 16}}>
                                                <Stack alignItems="center" direction="row" gap={0}>
                                                    {this.props.rowEditNumber !== index ? 
                                                    <>
                                                    <IconButton disabled={this.props.rowEditNumber !== -1} size="small" onClick={() => this.props.handleEdit(index)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton disabled={this.props.rowEditNumber !== -1} sx={{color: red[500]}}  size="small" onClick={() => this.props.handleDelete(index)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                    </> :
                                                    <>
                                                    <IconButton size="small" sx={{color: green[500]}} onClick={() => this.props.handleSave(row)}>
                                                        <CheckIcon />
                                                    </IconButton>
                                                    <IconButton sx={{color: red[500]}}  size="small" onClick={() => this.props.handleDiscard(index)}>
                                                        <CloseIcon />
                                                    </IconButton>
                                                    </>}
                                                </Stack>
                                            </div>
                                        </div>
                                        </Paper>
                                    </div>
                                    )}
                                </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </Box>
            </Box>
        </>
        );
    };
}

// const DraggableDataGrid: React.FC = () => {
//   const [rows, setRows] = useState(initialRows);

//   const onDragEnd = (result: DropResult) => {
//     if (!result.destination) return;

//     const reorderedRows = Array.from(rows);
//     const [removed] = reorderedRows.splice(result.source.index, 1);
//     reorderedRows.splice(result.destination.index, 0, removed);

//     setRows(reorderedRows);
//   };

//   return (
//     <DragDropContext onDragEnd={onDragEnd}>
//       <Droppable droppableId="droppable" direction="vertical">
//         {(provided) => (
//           <div ref={provided.innerRef} {...provided.droppableProps}>
//             <div
//                 style={{
//                     border: '1px solid #ccc',
//                 }}>
//             <Paper elevation={0}>
//                 <div style={{ display: 'flex', padding: 16, fontWeight: 500, fontSize: '0.875rem' }}>
//                     <div style={{width:"15%", paddingRight: 16}}></div>
//                     {columns.map((column) => (
//                         <div key={column.field} style={{ width: '100%' }}>
//                         {column.field}
//                         </div>
//                     ))}
//                 </div>
//             </Paper>
//             </div>
//             {rows.map((row, index) => (
//               <Draggable key={row.id} draggableId={row.id.toString()} index={index}>
//                 {(provided, snapshot) => (
//                   <div
//                     ref={provided.innerRef}
//                     {...provided.draggableProps}
//                     {...provided.dragHandleProps}
//                     style={{
//                       ...provided.draggableProps.style,
//                       //marginBottom: 8,
//                       //backgroundColor: snapshot.isDragging ? '#e0e0e0' : '#fff',
//                       border: '1px solid #ccc',
//                     }}
//                   >
//                     <Paper elevation={0}>
                        
//                       <div style={{ display: 'flex', padding: 16 }}>
//                         <div style={{width:"15%", paddingRight: 16}}>
//                             <DragIndicatorIcon />
//                         </div>
//                         {columns.map((column) => (
//                           <div key={column.field} style={{ width: '100%', fontSize: '0.875rem' }}>
//                             {row[column.field]}
//                           </div>
//                         ))}
//                       </div>
//                     </Paper>
//                   </div>
//                 )}
//               </Draggable>
//             ))}
//             {provided.placeholder}
//           </div>
//         )}
//       </Droppable>
//     </DragDropContext>
//   );
// };

export default DraggableDataGrid;