class LocalStorageService {
	static setTokens(accessToken: string, refreshToken: string){
		localStorage.setItem("accessToken", accessToken);
		localStorage.setItem("refreshToken", refreshToken);
	}

	static getAccessToken(): string | undefined {
		return localStorage.getItem("accessToken") || undefined;
	}

	static getRefreshToken(): string | undefined {
		return localStorage.getItem("refreshToken") || undefined;
	}

	static clearTokens() {
		localStorage.removeItem("accessToken");
		localStorage.removeItem("refreshToken");
	}

	static setLocalization(locale: string) {
		localStorage.setItem("locale", locale);
	}

	static getLocalization(): string | undefined {
		return localStorage.getItem("locale") || undefined;
	}
}

export default LocalStorageService;