import { Components } from "@mui/material"
import { Theme } from "@mui/system"
import { AppThemePalette } from "../AppThemePalette"

export default {
	MuiList: {
		styleOverrides: {
			root: {
				paddingTop: 0,
				paddingBottom: 0
			}
		}
	},
	MuiListItemIcon: {
		styleOverrides: {
			root: {
				minWidth: '30px'
			}
		}
	},
	MuiListItemButton: {
		styleOverrides: {
			root: {
				'&.Mui-selected': {
					background: AppThemePalette.secondary.main
				},
				'&.Mui-selected:hover': {
					background: AppThemePalette.secondary.main
				}
			}
		}
	},
	MuiListItem: {
		styleOverrides: {
			root: {
				'&.Mui-selected': {
					backgroundClip: "text",
					webkitBackgroundClip: 'text',
					color: 'transparent'
				}
			}
		}
	}
} as Components<Omit<Theme, "components">>