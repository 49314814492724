import { Route } from "react-router-dom";
import PageNotFound from "./pages/PageNotFound";
import Dashboard from "./pages/admin_portal/Dashboard/Dashboard";
import UsersList from "./pages/admin_portal/users/users-list";
import User from "./pages/admin_portal/users/user";
import ContactsList from "./pages/admin_portal/contacts/contacts-list";
import Contact from "./pages/admin_portal/contacts/contact";
import Setting from "./pages/admin_portal/settings/setting";
import EmailTemplatesList from "./pages/admin_portal/email-templates/email-templates-list";
import EmailTemplate from "./pages/admin_portal/email-templates/email-template";
import CampaignsList from "./pages/admin_portal/campaigns/campaigns-list";
import Campaign from "./pages/admin_portal/campaigns/campaign";
import FlowsList from "./pages/admin_portal/flows/flows-list";
import Flow from "./pages/admin_portal/flows/flow";
import TagsList from "./pages/admin_portal/tags/tags-list";
import Tag from "./pages/admin_portal/tags/tag";


export default [
	<Route path = "/admin/" element={<Dashboard />} key="/admin/"/>,
	<Route path = "/admin/users/" element={<UsersList />} key="/admin/users/" />,
	<Route path = "/admin/users/:id" element={<User />} key="/admin/users/:id" />,
	<Route path = "/admin/users/create" element={<User />} key="/admin/users/create/" />,
	<Route path = "/admin/contacts/" element={<ContactsList />} key="/admin/contacts/" />,
	<Route path = "/admin/contacts/:id" element={<Contact />} key="/admin/contacts/:id" />,
	<Route path = "/admin/contacts/create" element={<Contact />} key="/admin/contacts/create/" />,
	<Route path = "/admin/settings" element={<Setting />} key="/admin/settings/" />,
	<Route path = "/admin/email-templates/" element={<EmailTemplatesList />} key="/admin/email-templates/" />,
	<Route path = "/admin/email-templates/:id" element={<EmailTemplate />} key="/admin/email-templates/:id" />,
	<Route path = "/admin/email-templates/create" element={<EmailTemplate />} key="/admin/email-templates/create/" />,
	<Route path = "/admin/campaigns/" element={<CampaignsList />} key="/admin/campaigns/" />,
	<Route path = "/admin/campaigns/:id" element={<Campaign />} key="/admin/campaigns/:id" />,
	<Route path = "/admin/campaigns/create" element={<Campaign />} key="/admin/campaigns/create/" />,
	<Route path = "/admin/flows/" element={<FlowsList />} key="/admin/flows/" />,
	<Route path = "/admin/flows/:id" element={<Flow />} key="/admin/flows/:id" />,
	<Route path = "/admin/flows/create" element={<Flow />} key="/admin/flows/create/" />,
	<Route path = "/admin/tags/" element={<TagsList />} key="/admin/tags/" />,
	<Route path = "/admin/tags/:id" element={<Tag />} key="/admin/tags/:id" />,
	<Route path = "/admin/tags/create" element={<Tag />} key="/admin/tags/create/" />,
	<Route path = "/admin/*" element={<PageNotFound/>} key="/admin/*"/>
];