import { FormControl, InputLabel, Select, SelectProps } from '@mui/material';
import React, { PureComponent } from 'react';

type DropdownProps = SelectProps & {
	label?: string
}

interface DropdownState {

}

class Dropdown extends PureComponent<DropdownProps, DropdownState> {
	constructor(props: DropdownProps) {
		super(props);


		this.state = {
		}
	}

	onChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
		this.setState({
			value: event.target.value
		});
	}

	render() {
		return (
			<FormControl
				className={this.props.className}
				sx={this.props.sx}
				style={{
					marginTop: "0.3rem"
				}}>
				<InputLabel>
					{this.props.label}
				</InputLabel>
				
				<Select
					{...this.props}>
					{
						this.props.children
					}
				</Select>
			</FormControl>
		);
	}
}

export default Dropdown;