import { Box, Button, Checkbox, FormControlLabel, FormControl, FormGroup, TextField, Typography, Paper } from "@mui/material";
import React, { ChangeEvent } from "react";
import { FormattedMessage, WrappedComponentProps, injectIntl } from "react-intl";
import { WithRouterProps, withRouter } from "../../../../router";
import { ApplicationUser, AuthResponse } from "../../../../models/AuthModels";
import { JwtPayload } from "../../../../models/JwtPayload";
import { connect } from "react-redux";
import * as authActions from "../../../../store/actions/AuthActions";
import * as accountActions from "../../../../store/actions/AccountActions";
import http from "../../../../services/HttpService";
import Toaster from "../../../controls/toaster/Toaster";
import { appConfig } from "../../../../AppConfig";
import { jwtDecode } from "jwt-decode";
import { ValidationError } from "../../../../models/ValidationErrorModel";


interface AdminLoginProps extends WrappedComponentProps, WithRouterProps {
	login?: { (data: AuthResponse): void },
	setCurrentUser?: { (user: ApplicationUser): void }
}

interface AdminLoginState {
	email: string,
	emailIsValid?: boolean,
	emailErrorMessage?: string,
	password: string,
	passwordIsValid?: boolean,
	passwordErrorMessage?: string,
	rememberMe: boolean,
	canLogin: boolean
}

class AdminLogin extends React.Component<AdminLoginProps, AdminLoginState> {
	constructor(props: AdminLoginProps) {
		super(props);

		this.state = {
			email: "",
			password: "",
			rememberMe: false,
			canLogin: false
		}
	}

	async onFieldChangeAsync(event: ChangeEvent<HTMLInputElement>) {
		const { name, value } = event.target;

		if (name === "username") {
			// let emailRegex = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);

			 const isValid = value.length >= 1;
			// 	&& emailRegex.test(value);

			await this.setState({
				email: value,
				emailIsValid: isValid,
				emailErrorMessage: !isValid ? "Username is required" : ""
			});
		}

		if (name === "password") {
			const isValid = value.length >= 1;

			await this.setState({
				password: value,
				passwordIsValid: isValid,
				passwordErrorMessage: !isValid ? "Password is required" : ""
			});
		}

		if(name === "rememberMe"){
			await this.setState({
				rememberMe: !this.state.rememberMe ///^true$/i.test(value)
			});
		}

		const canLogin = this.state.emailIsValid
			&& this.state.passwordIsValid;

		this.setState({
			canLogin: canLogin ?? false
		});
	}

	handleLogin(event: any) {
		event.preventDefault();

		// add real login request to the Rest API here
	
		http.post(`/authorization/signin`, 
			{
				username: this.state.email, 
				password: this.state.password, 
				rememberMe: this.state.rememberMe
			})
			.then((response) => {
				this.props.login?.({
					accessToken: response.data.accessToken,
					refreshToken: response.data.refreshToken
				});

				const decoded = jwtDecode<JwtPayload>(response.data.accessToken);
				this.props.setCurrentUser?.({
					id: decoded.id,
					email: this.state.email,
					username: this.state.email,
					roles: decoded.roles
				});

				this.props.navigate("/admin");
			}).catch((error) => {
				let errors = error.data.errors as Array<ValidationError>;
				if(errors){
					errors.map(item => {
						if(item.property.toLowerCase() === "username"){
							this.setState({
								emailIsValid: false,
								emailErrorMessage: item.message
							});
						}
						if(item.property.toLowerCase() === "password"){
							this.setState({
								passwordIsValid: false,
								passwordErrorMessage: item.message
							});
						}
					})
				}
				else{
					Toaster.Show("error", "Error", error.data.message);
					this.setState({
						passwordIsValid: false,
						emailIsValid: false
					});
				}
			});
	}

	render() {
		return (
			<>
				<Paper>
					<Box
						width={"20rem"}
						padding="1rem">
						<Typography component="h1" variant="h5">
							<FormattedMessage id="labels_login" />
						</Typography>
						<form>
							<FormGroup sx={{
								marginTop: "1rem"
							}}>
								<FormControl>
									<TextField
										name="username"
										sx={{
											width: "100%"
										}}
										label={this.props.intl.formatMessage({ id: "labels_username" })}
										error={this.state.emailIsValid === false}
										onChange={this.onFieldChangeAsync.bind(this)}
										onBlur={(event) => this.onFieldChangeAsync(event as ChangeEvent<HTMLInputElement>)} 
										helperText={this.state.emailErrorMessage} />
								</FormControl>
								<FormControl margin="dense">
									<TextField
										name="password"
										sx={{
											width: "100%"
										}}
										label={this.props.intl.formatMessage({ id: "labels_password" })}
										type="password"
										error={this.state.passwordIsValid === false}
										onChange={this.onFieldChangeAsync.bind(this)}
										onBlur={(event) => this.onFieldChangeAsync(event as ChangeEvent<HTMLInputElement>)}
										helperText={this.state.passwordErrorMessage} />
								</FormControl>
								<FormControl margin="dense">
									<FormControlLabel
										control={
											<Checkbox 
												onChange={this.onFieldChangeAsync.bind(this)}
												name="rememberMe" 
												size="medium" />
										}
										label="Remember me"
									/>
								</FormControl>
							</FormGroup>
							<FormGroup
								sx={{
									justifyContent: "right"
								}}>
								<Button
									type="submit"
									variant="contained"
									color="primary"
									disabled={!this.state.canLogin}
									onClick={this.handleLogin.bind(this)}
									sx={{
										marginTop: "1rem"
									}}>
									<FormattedMessage id="labels_signin" />
								</Button>
							</FormGroup>
						</form>
					</Box>
				</Paper>
			</>
		);
	}
}

const mapDispatchToProps = (dispatch: any) => {
	return {
		login: (authResponse: AuthResponse) =>
			dispatch(authActions.UserLoggedIn(authResponse)),
		setCurrentUser: (user: ApplicationUser) =>
			dispatch(accountActions.SetCurrentUser(user))
	};
};

export default withRouter(injectIntl(connect(null, mapDispatchToProps)(AdminLogin)));