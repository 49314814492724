import React, { ChangeEvent } from "react";
import { FormattedMessage, WrappedComponentProps, injectIntl } from "react-intl";
import { WithRouterProps, withRouter } from "../../../../router";
import { connect } from "react-redux";
import { Box, Button, Container, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, Paper, Switch, TextField } from "@mui/material";
import http from "../../../../services/HttpService";
import Toaster from "../../../controls/toaster/Toaster";
import { ValidationError } from "../../../../models/ValidationErrorModel";
import { EmailTemplateModel } from "../../../../models/EmailTemplateModels";
import Editor from "../../../controls/rich-text-editor/Editor";
import { RichTextEditorRef } from "mui-tiptap/dist/RichTextEditor";

interface EmailTemplateProps extends WrappedComponentProps, WithRouterProps {

}

interface EmailTemplateState {
	id?: string;
	name: string;
    nameIsValid?: boolean;
    nameErrorMessage?: string;
    description: string;
    descriptionIsValid?: boolean;
    descriptionErrorMessage?: string;
    subject: string;
    subjectIsValid?: boolean;
    subjectErrorMessage?: string;
	content: string;
    contentIsValid?: boolean;
    contentErrorMessage?: string;
    isLocked: boolean;
	isActive: boolean;
	isPlaneText: boolean;
}

class EmailTemplate extends React.Component<EmailTemplateProps, EmailTemplateState> {
    private rteRef: React.RefObject<RichTextEditorRef>;
	constructor(props: EmailTemplateProps) {
		super(props);

		this.state = {
			name: "",
            description: "",
            subject: "",
			content: "",
            isLocked: false,
			isActive: true,
			isPlaneText: false
		}
		
        this.rteRef = React.createRef<RichTextEditorRef>();

		if(this.props.params?.id){
			this.getData();
		}
	}

	async onFieldChangeAsync(event: ChangeEvent<HTMLInputElement>) {
		const { name, value } = event.target;

		if (name === "name" && !this.state.isLocked) {
			const isValid = value.length >= 1;

			await this.setState({
				name: value,
				nameIsValid: isValid,
				nameErrorMessage: !isValid ? "Name is required" : ""
			});
		}

        if (name === "description" && !this.state.isLocked) {
			await this.setState({
				description: value,
				descriptionIsValid: true,
				descriptionErrorMessage: ""
			});
		}

        if (name === "subject" && !this.state.isLocked) {
			const isValid = value.length >= 1;

			await this.setState({
				subject: value,
				subjectIsValid: isValid,
				subjectErrorMessage: !isValid ? "Subject is required" : ""
			});
		}

		if (name === "content" && !this.state.isLocked) {
			const isValid = value.length >= 1;

			await this.setState({
				content: value,
				contentIsValid: isValid,
				contentErrorMessage: !isValid ? "Content is required" : ""
			});
		}

		if(name === "isactive" && !this.state.isLocked){
			await this.setState({
				isActive: !this.state.isActive
			});
		}

		if(name === "isplanetext" && !this.state.isLocked) {
			await this.setState({
				isPlaneText: !this.state.isPlaneText
			});
		}
	}

	handleCancel(event: React.MouseEvent<unknown>) {
		this.props.navigate("/admin/email-templates");
	}

	handleSaveChanges(event: React.MouseEvent<unknown>) {
		this.saveChanges();
	}

	saveChanges(){
		const params = {
			name: this.state.name,
			description: this.state.description,
            subject: this.state.subject,
            content: this.state.isPlaneText ? this.state.content : this.rteRef.current?.editor?.getHTML(),
			isPlaneText: this.state.isPlaneText,
			isActive: this.state.isActive
		  };

		if(this.state.id){
			http.put(`/emailTemplates/${this.state.id}`, params)
			.then((response) => {
				this.props.navigate("/admin/email-templates");
			}).catch((error) => {
				let errors = error.data?.errors as Array<ValidationError>;
				if(errors){
					errors.map(item => {
						switch(item.property.toLocaleLowerCase()){
							case "name": {
								this.setState({
									nameIsValid: false,
									nameErrorMessage: item.message
								});
								break;
							}
                            case "description": {
								this.setState({
									descriptionIsValid: false,
									descriptionErrorMessage: item.message
								});
								break;
							}
                            case "subject": {
								this.setState({
									subjectIsValid: false,
									subjectErrorMessage: item.message
								});
								break;
							}
                            case "content": {
								this.setState({
									contentIsValid: false,
									contentErrorMessage: item.message
								});
								break;
							}
							default: {
								Toaster.Show("error", "Error", item?.message);
								break;
							}
						}
					})
				}
				else{
					Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
				}
			});
		}
		else{
			http.post(`/emailTemplates`, params)
				.then((response) => {
					this.props.navigate("/admin/email-templates");
				}).catch((error) => {
					let errors = error.data?.errors as Array<ValidationError>;
					if(errors){
						errors.map(item => {
							switch(item.property.toLocaleLowerCase()){
								case "name": {
                                    this.setState({
                                        nameIsValid: false,
                                        nameErrorMessage: item.message
                                    });
                                    break;
                                }
                                case "description": {
                                    this.setState({
                                        descriptionIsValid: false,
                                        descriptionErrorMessage: item.message
                                    });
                                    break;
                                }
                                case "subject": {
                                    this.setState({
                                        subjectIsValid: false,
                                        subjectErrorMessage: item.message
                                    });
                                    break;
                                }
                                case "content": {
                                    this.setState({
                                        contentIsValid: false,
                                        contentErrorMessage: item.message
                                    });
                                    break;
                                }
								default: {
									Toaster.Show("error", "Error", item?.message);
									break;
								}
							}
						})
					}
					else{
						Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
					}
				});
		}
	}

	getData(){
		http.get(`/emailTemplates/${this.props.params?.id}`)
			.then((response) => {
				let responseData = response.data as EmailTemplateModel;

				this.setState({
					id: responseData.id,
					name: responseData.name,
					description: responseData.description ?? "",
					subject: responseData.subject,
					isLocked: responseData.isLocked,
					isActive: responseData.isActive,
					isPlaneText: responseData.isPlaneText
				},
				function () {

				});

				if(!responseData.isPlaneText){
					this.rteRef?.current?.editor?.commands.setContent(responseData.content);
				}
				else{
					this.setState({
						content: responseData.content
					});
				}
			}).catch((error) => {
				Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
			});
	}

	render(): React.ReactNode {
		return(
			<Paper>
				<form>
					<FormGroup 
						sx={{
						pt: { xs: 3 },
						//pb: { xs: 3 },
						pr: { xs: 2 },
						pl: { xs: 2 }
					}}>
						<Grid container>
							<Grid item xs={5}>
								<FormControl
									sx={{
										width: "100%",
										pr: { xs: 2}
									}}>
									<TextField
										required
										fullWidth
										name="name"
										value={this.state.name}
										inputProps={{
											form: {
											  autoComplete: 'off',
											},
										  }}
										label={this.props.intl.formatMessage({ id: "labels_name" })}
										error={this.state.nameIsValid === false}
										onChange={this.onFieldChangeAsync.bind(this)}
										onBlur={(event) => this.onFieldChangeAsync(event as ChangeEvent<HTMLInputElement>)} 
										helperText={this.state.nameErrorMessage} />
								</FormControl>
							</Grid>
							<Grid item xs={5}>
                                <FormControl
                                        sx={{
                                            width: "100%",
                                            pr: { xs: 2}
                                        }}>
									<TextField
										fullWidth
										name="description"
										value={this.state.description}
										inputProps={{
											form: {
											  autoComplete: 'off',
											},
										  }}
										label={this.props.intl.formatMessage({ id: "labels_description" })}
										error={this.state.descriptionIsValid === false}
										onChange={this.onFieldChangeAsync.bind(this)}
										onBlur={(event) => this.onFieldChangeAsync(event as ChangeEvent<HTMLInputElement>)} 
										helperText={this.state.descriptionErrorMessage} />
								</FormControl>
							</Grid>
                            <Grid item xs={2}>
                                <FormControl
                                    sx={{
                                        width: "100%",
                                        pr: { xs: 2}
                                    }}>
									<FormControlLabel 
										control={
											<Switch 
												name="isactive" 
												onChange={this.onFieldChangeAsync.bind(this)} 
												checked={this.state.isActive } />} 
										label="Is Active?" />
								</FormControl>
                            </Grid>
						</Grid>
					</FormGroup>
					<FormGroup 
						sx={{
							pt: { xs: 2 },
							//pb: { xs: 3 },
							//pr: { xs: 2 },
							pl: { xs: 2 }
						}}>
						<Grid container>
							<Grid item xs={10}>
                                <FormControl
									sx={{
										width: "100%",
										pr: { xs: 2}
									}}>
									<TextField
										required
										fullWidth
										name="subject"
										value={this.state.subject}
										inputProps={{
											form: {
											  autoComplete: 'off',
											},
										  }}
										label={this.props.intl.formatMessage({ id: "labels_subject" })}
										error={this.state.subjectIsValid === false}
										onChange={this.onFieldChangeAsync.bind(this)}
										onBlur={(event) => this.onFieldChangeAsync(event as ChangeEvent<HTMLInputElement>)} 
										helperText={this.state.subjectErrorMessage} />
								</FormControl>
							</Grid>
							<Grid item xs={2}>
								<FormControl
                                    sx={{
                                        width: "100%",
                                        pr: { xs: 2}
                                    }}>
									<FormControlLabel 
										control={
											<Switch 
												name="isplanetext" 
												onChange={this.onFieldChangeAsync.bind(this)} 
												checked={this.state.isPlaneText } />} 
										label="Is Plane Text?" />
								</FormControl>
							</Grid>
						</Grid>
					</FormGroup>
                    <FormGroup 
						sx={{
							pt: { xs: 2 },
							//pb: { xs: 3 },
							//pr: { xs: 2 },
							pl: { xs: 2 }
					}}>
						<Grid container>
							<Grid item xs={12}>
									{
									this.state.isPlaneText ? 
									<FormControl
                                        sx={{
                                            width: "100%",
                                            pr: { xs: 2}
                                        }}>
										<TextField 
											required
											fullWidth
											multiline
											name="content"
											value={this.state.content}
											inputProps={{
												form: {
												autoComplete: 'off',
												},
											}}
											label={this.props.intl.formatMessage({ id: "labels_content" })}
											error={this.state.contentIsValid === false}
											onChange={this.onFieldChangeAsync.bind(this)}
											onBlur={(event) => this.onFieldChangeAsync(event as ChangeEvent<HTMLInputElement>)} 
											helperText={this.state.contentErrorMessage}/> 
									</FormControl>:
									<FormControl
										error={this.state.contentIsValid === false}
										sx={{
											width: "100%",
											pr: { xs: 2}
										}}>
										<Editor rteRef={this.rteRef} />
										<FormHelperText error={this.state.contentIsValid === false}>{this.state.contentErrorMessage}</FormHelperText>
									</FormControl>
									}
							</Grid>
						</Grid>
					</FormGroup>
					<FormGroup 
						sx={{
							pt: { xs: 2 },
							pb: { xs: 3 },
							//pr: { xs: 2 },
							//pl: { xs: 2 }
					}}>
						<Grid container>
							<Grid item lg={9} md={8} sm={7} xs={6} />
							<Grid item lg={3} md={4} sm={5} xs={6} >
								<Box sx={{display: 'flex', alignItems: 'center', mr: 2}}>
									<Button
										sx={{
											px: 2,
											mr: 1,
											ml: "auto"
										}}
										onClick={this.handleCancel.bind(this)}
										variant="outlined">
										Cancel
									</Button>
									<Button
										// sx={{
										// 	px: 2,
										// }}
										onClick={this.handleSaveChanges.bind(this)}
										variant="contained">
										Save
									</Button>
								</Box>
							</Grid>
						</Grid>
					</FormGroup>
				</form>
			</Paper>
		);
	}
}

const mapDispatchToProps = (dispatch: any) => {
	return {
	};
};

export default withRouter(injectIntl(connect(null, mapDispatchToProps)(EmailTemplate)));