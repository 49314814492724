import { Components } from "@mui/material"
import { Theme } from "@mui/system"

export default {
	MuiButton: {
		styleOverrides: {
			root: {
				marginTop: "0.5rem"
			}
		}
	}
} as Components<Omit<Theme, "components">>