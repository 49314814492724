import { Box, Button, Container, FormGroup, Grid, Link, Stack, TextField, Typography } from "@mui/material";
import React, { ChangeEvent } from "react";
import PTLogo from '../../../../assets/images/pt.svg';
import PTApp1 from '../../../../assets/images/pt_app_1.svg';
import PTApp2 from '../../../../assets/images/pt_app_2.svg';
import GoogleStore from '../../../../assets/images/google_store.svg';
import AppleStore from '../../../../assets/images/apple_store.svg';
import SubscribedDialog from "../../../dialogs/subscribed_dialog/SubscribedDialog";
import http from "../../../../services/HttpService";
import { ValidationError } from "../../../../models/ValidationErrorModel";
import Toaster from "../../../controls/toaster/Toaster";
import { FormattedMessage, WrappedComponentProps, injectIntl } from "react-intl";
import { withRouter, WithRouterProps } from "../../../../router";
import { connect } from "react-redux";
import history from "../../../../History";
import UnsubscribedDialog from "../../../dialogs/unsubscribe_dialog/UnsubscribedDialog";

interface HomeProps extends WrappedComponentProps, WithRouterProps {

}

interface HomeState {
	email: string;
	emailIsValid?: boolean;
	emailErrorMessage?: string;
}

class Home extends React.Component<HomeProps, HomeState> {
	constructor(props: HomeProps) {
		super(props);

		this.state = {
			email: ""
		}

		if(this.props.params?.id) {
			var contactId = this.props.params.id;
			if(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(contactId)){
				this.unsubscribe(contactId);
			}
			else {
				history.replace("/");
			}
		}
	}

	async onFieldChangeAsync(event: ChangeEvent<HTMLInputElement>) {
		const { name, value } = event.target;

		if (name === "email") {
			let emailRegex = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);

			const isValid = value.length >= 1 && emailRegex.test(value);

			await this.setState({
				email: value,
				emailIsValid: isValid,
				emailErrorMessage: !isValid ? "Field should contain valid email address" : ""
			});
		}
	}

	unsubscribe(contactId: string) {
		http.put(`/contacts/unsubscribe/${contactId}`)
		.then((response) => {
			UnsubscribedDialog.Show();
		})
		.catch((error) => {
			Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
		});

		history.replace("/");
	}

	handleSaveChanges(event: React.MouseEvent<unknown>) {
		const params = {
			email: this.state.email,
		};

		http.post('/contacts/subscribe', params)
		.then((response) => {
			SubscribedDialog.Show();
		})
		.catch((error) => {
			let errors = error.data?.errors as Array<ValidationError>;
			if(errors){
				errors.map(item => {
					switch(item.property.toLocaleLowerCase()){
						case "email": {
							this.setState({
								emailIsValid: false,
								emailErrorMessage: item.message
							});
							break;
						}
						default: {
							Toaster.Show("error", "Error", item?.message);
							break;
						}
					}
				})
			}
			else{
				Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
			}
		});
	}

	render(): React.ReactNode {
		return(
			<>
				<Stack spacing={{xs: 2, md: 8}} sx={{p: {xs: "15px"}}} maxWidth={{ xs: "xs", sm: "sm", md: "md", lg: "lg"}}>
				<Box component="section" sx={{
						backgroundColor: "#29CCA1",
						borderRadius: { xs: 5 }
					}}>
						<Grid container sx={{justifyContent: "center", alignItems: "center"}}>
							<Grid item sm={12} md={7} lg={7} xl={7}>
								<FormGroup sx={{p: 5}}>
									<Typography sx={{fontWeight: 900, color: "#000000", fontSize: {sm: "55px", xs: "28px"}, lineHeight: 1.2}}>
										Ready to win big with our exclusive weekend predictions?
									</Typography>
									<Typography sx={{color: "#FFFFFF", mt: 3, fontWeight: 400, fontSize: {sm: "30px", xs: "18px"}, lineHeight: 1.2}}>
										Subscribe to our newsletter, powered by the ProfitTips app and receive FREE football predictions every weekend!
									</Typography>
									<Box sx={{m: "auto"}} display={{md: "none", lg: "none", xl: "none"}} width={{xs: "162px", sm: "365px"}}>
										<FormGroup sx={{p: 5}}>
											<img width={"100%"} src={PTLogo} />
										</FormGroup>
									</Box>
									<Typography sx={{display: "inline-block", color: "#FFFFFF", mt: {lg: 8}, fontWeight: 400, fontSize: {sm: "18px", xs: "14px"}, lineHeight: 1.2}}>
										Join over 60,000 subscribers, who receive our newsletter completely free. Don’t miss out on the best football tips: <strong>JOIN  FOR FREE NOW.</strong>
									</Typography>
									<TextField 
										fullWidth 
										label={this.state.email.length > 1 ? "" : "Enter your email"} 
										variant="outlined" 
										sx={{ 
											mt: 2, 
											mb: 2, 
											color: "#000000", 
											backgroundColor:"#CAF4E9", 
											borderColor: "#000000", 
											borderRadius: 4,
											'& .MuiOutlinedInput-root': {'& fieldset': {borderRadius: 4, borderColor: "#000000"}, 
											'&.Mui-focused fieldset': { borderColor: '#000000'}, 
											'&.Mui-focused': { color: '#000000'}}, 
											'& .MuiInputLabel-root': {color: '#000000'}, 
											'& .MuiInputLabel-root.Mui-focused': {color: '#000000'},
											'.MuiOutlinedInput-root': {
												borderRadius: 4,
												borderColor: "#000000"
											}}} 
											name="email"
											value={this.state.email}
											onChange={this.onFieldChangeAsync.bind(this)}
											onBlur={(event) => this.onFieldChangeAsync(event as ChangeEvent<HTMLInputElement>)} 
											helperText={this.state.emailErrorMessage}
											error={this.state.emailIsValid === false}/>
									<div>
										<Button 
											size="large" 
											variant="outlined" 
											sx={{
												fontWeight: 700, 
												fontSize: {xs: "24px"}, 
												backgroundColor: "#FFCC02", 
												borderColor:"#FFCC02", 
												color: "#000000", 
												borderRadius: 3, 
												"&:hover": {backgroundColor: "#FFCC02"}
											}} 
											onClick={this.handleSaveChanges.bind(this)}>
											JOIN FOR FREE
										</Button>
									</div>
								</FormGroup>
							</Grid>
							<Grid item sm={0} md={5} lg={5} xl={5}>
								<Box sx={{m: "auto"}} display={{xs: "none", sm: "none", md: "block"}} width={{xs: "162px", sm: "365px"}}>
									<FormGroup sx={{p: 5}}>
										<img width={"100%"} src={PTLogo} />
									</FormGroup>
								</Box>
							</Grid>
						</Grid>
					</Box>

					<Box component="section" sx={{
						borderColor: "#000000",
						borderRadius: { xs: 5 },
						border: 1,
					}}>
						<Grid container sx={{justifyContent: "center", alignItems: "center"}}>
							<Grid item sm={0} md={5} lg={5} xl={5}>
								<Box sx={{m: "auto"}} display={{xs: "none", sm: "none", md: "block"}} width={{xs: "265px", sm: "357px"}}>
									<FormGroup sx={{m: 5}} >
										<img width={"100%"} src={PTApp1} />
									</FormGroup>
								</Box>
							</Grid>
							<Grid item sm={12} md={7} lg={7} xl={7}>
								<FormGroup sx={{p: 5}}>
									<Typography variant="h4" component="h4" sx={{fontWeight: 900, color: "#000000", fontSize: {sm: "30px", xs: "24px"}, lineHeight: 1.2}}>
										Get ProfitTips 
									</Typography>
									<Typography sx={{mt: 2, fontWeight: 400, fontSize: {xs: "16px"}, lineHeight: 1.2}}>
										For complete access to expert predictions, Subscribe to our newsletter for FREE, then Download our partner application ProfitTips onto your phone, free of charge and, discover the world of first-rate analysis.
									</Typography>
									<Typography sx={{mt: 4, fontWeight: 400, fontSize: {xs: "16px"}, lineHeight: 1.2}}>
										Professionals in the sports betting industry created the ProfitTips App. Their team meticulously examines football teams and players, delving into the smallest details. They monitor numerous statistics and analyze hundreds of daily events to provide our customers with the best possible analysis.
									</Typography>

									<Box sx={{m: "auto"}} display={{ md: "none", lg: "none", xl: "none"}} width={{xs: "265px", sm: "357px"}}>
										<FormGroup sx={{p: 5}}>
											<img width={"100%"} src={PTApp1} />
										</FormGroup>
									</Box>

									<Typography sx={{color: "#606060", pb: 1, mt: {xs: 0, md: 7}, fontWeight: 400, fontSize: {xs: "16px"}, lineHeight: 1.2}}>
										Download App from
									</Typography>
									<Box sx={{display: "flex", flexWrap: "wrap"}}>
										<Link sx={{mr: 2}} target="_blank" href="https://itunes.apple.com/bg/app/profittips/id1317622032?mt=8">
											<img src={AppleStore} />
										</Link>
										<Link target="_blank" href=" https://play.google.com/store/apps/details?id=com.profittips&hl=en">
											<img src={GoogleStore} />
										</Link>
									</Box>
								</FormGroup>
							</Grid>
						</Grid>
					</Box>

					<Box component="section" sx={{
						borderColor: "#000000",
						borderRadius: { xs: 5 },
						border: 1
					}}>
						<Grid container sx={{justifyContent: "center", alignItems: "center"}}>
							<Grid item sm={12} md={5} lg={6} xl={7}>
								<FormGroup sx={{p: 5}}>
									<Typography variant="h4" component="h4" sx={{fontWeight: 900, color: "#000000", fontSize: {sm: "30px", xs: "24px"}, lineHeight: 1.2}}>
										Get ProfitTips Limited Time Promotions
									</Typography>
									<Typography sx={{mt: 2, fontWeight: 400, fontSize: {xs: "16px"}, lineHeight: 1.2}}>
										Complete your Order for a Bundle Membership, which includes Combo, Extra and VIP plans +  a bonus: our Newsletter, for free!
									</Typography>
									<Typography sx={{mt: 2, fontWeight: 400, fontSize: {xs: "16px"}, lineHeight: 1.2}}>
										By purchasing our memberships, which are at the best possible prices, you’ll be able to find dozens of suggestions every day. ProfitTips daily Combo is a real hit amongst football fans but, you can also take a look at Extra where high-rate matches are published including daily VIP advice. Additionally, along with expert predictions, you can access information for the best casino and sports bonuses via our newsletter!
									</Typography>
								</FormGroup>
							</Grid>
							<Grid item sm={0} md={7} lg={6} xl={5}>
								<Box sx={{m: "auto"}} width={{xs: "100%", sm: "520px"}}>
									<FormGroup sx={{p: 5}} >
										<img width={"100%"} src={PTApp2} />
									</FormGroup>
								</Box>
							</Grid>
						</Grid>
					</Box>
				</Stack>
			</>
		);
	}
}

const mapDispatchToProps = (dispatch: any) => {
	return {
	};
};

export default withRouter(injectIntl(connect(null, mapDispatchToProps)(Home)));