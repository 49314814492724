import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, MenuItem, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React from 'react';
import ConfirmDialog from '../dialogs/confirm_dialog/ConfirmDialog';
import Toaster from '../controls/toaster/Toaster';
import Dropdown from '../controls/dropdown/Dropdown';

class UITestPage extends React.Component {
	showConfirmDialog() {
		ConfirmDialog.Show(
			"danger",
			"Test",
			"This is test confirm message",
			[
				{
					text: "Test button",
					props: {
						variant: "contained",
						color: "error",
						onClick: () => { window.alert("Testing") }
					}
				}
			]
		);
	}

	showNormalToaster() {
		Toaster.Show("info", "Normal", "Normal Toaster");
	}

	showWarningToaster() {
		Toaster.Show("warning", "Warning", "Warning Toaster");
	}

	showErrorToaster() {
		Toaster.Show("error", "Error", "Error Toaster");
	}

	showSuccessToaster() {
		Toaster.Show("success", "Success", "Success Toaster");
	}

	render() {
		return (
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					padding: '2rem'
				}}>
				<Box sx={{ width: '600px' }}>
					<Typography variant={"h5"}>Text fields</Typography>
					<FormGroup sx={{marginTop: "0.5rem"}}>
						<TextField
							label="Single outlined line medium" />
					</FormGroup>

					<FormGroup sx={{marginTop: "0.5rem"}}>
						<TextField
							size="small"
							label="Single outlined line small" />
					</FormGroup>

					<FormGroup sx={{marginTop: "0.5rem"}}>
						<TextField
							size="medium"
							label="Multiple line outlined medium"
							multiline={true} />
					</FormGroup>

					<FormGroup sx={{marginTop: "0.5rem"}}>
						<TextField
							size="small"
							label="Multiple line outlined small"
							multiline={true} />
					</FormGroup>

					<Typography
						marginTop={"1rem"}
						variant={"h5"}>
						Dropdowns
					</Typography>
					<FormGroup sx={{marginTop: "0.5rem"}}>
						<Dropdown
							sx={{
								width: "200px"
							}}
							label="Dropdown">
							<MenuItem>First</MenuItem>
							<MenuItem>Second</MenuItem>
						</Dropdown>
					</FormGroup>

					<Typography
						marginTop={"1rem"}
						variant={"h5"}>
						Buttons
					</Typography>
					<FormGroup>
						<Button
							size='medium'
							variant='contained'>
							Medium button contained
						</Button>

						<Button
							size='medium'
							variant='outlined'>
							Medium button outlined
						</Button>

						<Button
							size='medium'
							variant='text'>
							Medium button text
						</Button>
					</FormGroup>
					<FormGroup>
						<Button
							size='medium'
							color='error'
							variant='contained'>
							Danger button contained
						</Button>

						<Button
							size='medium'
							color='error'
							variant='outlined'>
							Danger button outlined
						</Button>

						<Button
							size='medium'
							color='error'
							variant='text'>
							Danger button text
						</Button>
					</FormGroup>
					<FormGroup>
						<Button
							variant='contained'
							disabled={true}>
							Disabled
						</Button>

						<Button
							variant='outlined'
							disabled={true}>
							Disabled
						</Button>

						<Button
							variant='text'
							disabled={true}>
							Disabled
						</Button>
					</FormGroup>
					<FormGroup>
						<Button
							size='small'
							variant='contained'>
							Small button contained
						</Button>

						<Button
							size='small'
							variant='outlined'>
							Small button outlined
						</Button>

						<Button
							size='small'
							variant='text'>
							Small button text
						</Button>
					</FormGroup>

					<Typography
						marginTop={"1rem"}
						variant={"h5"}>
						Checkboxes
					</Typography>
					<FormGroup>
						<FormControlLabel
							control={
								<Checkbox size="medium" />
							}
							label="Medium checkbox"
						/>
						<FormControlLabel
							control={
								<Checkbox size="small" />
							}
							label="Small checkbox"
						/>
					</FormGroup>

					<Typography
						marginTop={"1rem"}
						variant={"h5"}>
						Radios
					</Typography>
					<FormGroup>
						<RadioGroup name="test-ratio" defaultValue="medium">
							<FormControlLabel
								value={"medium"}
								control={
									<Radio size="medium" />
								}
								label="Medium radio"
							/>
							<FormControlLabel
								value="small"
								control={
									<Radio size="small" />
								}
								label="Small radio"
							/>
						</RadioGroup>

					</FormGroup>
				</Box>
				<Box sx={{ flex: 1, marginLeft: "2rem" }}>
					<Typography variant={"h1"}>This is sample h1 text</Typography>
					<Typography variant={"h2"}>This is sample h2 text</Typography>
					<Typography variant={"h3"}>This is sample h3 text</Typography>
					<Typography variant={"h4"}>This is sample h4 text</Typography>
					<Typography variant={"h5"}>This is sample h5 text</Typography>
					<Typography variant={"h6"}>This is sample h6 text</Typography>
					<br />	
					<Typography variant={"body1"}>
						Body 1 text - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
					</Typography>
					<br />	
					<Typography variant={"body2"}>
						Body 2 text - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
					</Typography>
					
					<br />
					<Typography fontWeight={"bold"} component={"span"}>This is some bold text</Typography>
					<br />
					<Typography component={"i"}>This is some info text</Typography>
					<br />
					<Typography variant={"caption"}>This is some small text</Typography>
					<Box marginTop={"2rem"}>
						<Button variant='contained' onClick={this.showConfirmDialog.bind(this)}>Show Confirm dialog</Button>
					</Box>
					<Box marginTop={"2rem"}>
						<Button variant='text' onClick={this.showNormalToaster.bind(this)}>Show normal toaster</Button>
						<Button variant='text' onClick={this.showWarningToaster.bind(this)}>Show warning toaster</Button>
						<Button variant='text' onClick={this.showErrorToaster.bind(this)}>Show error toaster</Button>
						<Button variant='text' onClick={this.showSuccessToaster.bind(this)}>Show success toaster</Button>
					</Box>
				</Box>
			</Box>
		);
	}
};

export default UITestPage;