import { Route } from "react-router-dom";
import PageNotFound from "./pages/PageNotFound";
import AdminLogin from "./pages/landing/login/AdminLogin";
import Home from "./pages/landing/home/Home";

export default [	
	<Route path="/" element={<Home/>} key="/" />,
	<Route path="/unsubscribe/:id" element={<Home/>} key="/unsubscribe/:id" />,
	<Route path="/admin/login" element={<AdminLogin/>} key="/admin/login" />,
	<Route path="*" element={<PageNotFound/>} key="*" />
];
