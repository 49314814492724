import { Location, NavigateFunction, useLocation, useNavigate, useParams } from "react-router-dom";

export interface WithRouterProps {
	location: Location,
	navigate: NavigateFunction,
	params: any
}

export function withRouter(Component: any) {
	function ComponentWithRouterProp(props: any) {
		const location = useLocation();
		const navigate = useNavigate();
		const params = useParams();

		return (
			<Component
				{...props}
				location={location}
				navigate={navigate}
				params={params}
			/>
		)
	}

	return ComponentWithRouterProp;
}