import { Typography } from "@mui/material";
import React from "react";

interface DashboardProps {

}

interface DashboardState {

}

class Dashboard extends React.Component<DashboardProps, DashboardState> {
	constructor(props: DashboardProps) {
		super(props);

		this.state = {

		}
	}

	render() {
		return(
			<Typography>
				This is the admin panel dashboard
			</Typography>
		);
	}
}

export default Dashboard;