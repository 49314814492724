import React from "react";
import ReactDOM from 'react-dom/client';

import { Snackbar, Alert, AlertTitle, IconButton } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import AppTheme from "../../../styles/AppTheme";
import { unmountComponentAtNode } from "react-dom";

type ToastSeverity = 'success' | 'info' | 'warning' | 'error';

interface ToasterProps {
	disableAutoHide?: boolean,
	title?: string,
	message: string,
	severity: ToastSeverity
}

interface ToasterState {
	isOpen: boolean
}

let toasterRoot = undefined as ReactDOM.Root | undefined;

class Toaster extends React.Component<ToasterProps, ToasterState> {
	timeout: NodeJS.Timeout | null;

	constructor(props: ToasterProps) {
		super(props);

		this.timeout = null;

		this.state = {
			isOpen: false
		};
	}

	static Show(severity: ToastSeverity, title: string, message: string, disableAutoHide: boolean | undefined = undefined) {
		let containerElement = document.getElementById("toasts-wrapper");

		if (containerElement) {
			toasterRoot?.unmount();
			containerElement.remove();
		}

		containerElement = document.createElement('div');
		containerElement.id = "toasts-wrapper";
		document.body.appendChild(containerElement);

		toasterRoot = ReactDOM.createRoot(containerElement as HTMLElement);

		toasterRoot.render(
			<AppTheme>
				<Toaster
					title={title}
					severity={severity}
					message={message}
					disableAutoHide={disableAutoHide}
				/>
			</AppTheme>
		);
	}

	componentDidMount() {
		this.setState({
			isOpen: true
		});

		if (!this.props.disableAutoHide) {
			this.timeout = setTimeout(this.closeToast.bind(this), 8000);
		}
	}

	componentWillUnmount(): void {
		if (this.timeout) {
			clearTimeout(this.timeout);
			this.timeout = null
		}
	}

	closeToast = () => {
		this.setState({
			isOpen: false
		});
		
		let wrapper = document.getElementById("toasts-wrapper");
		wrapper?.remove();

		if (this.timeout) {
			clearTimeout(this.timeout);
			this.timeout = null
		}
	}

	render() {
		return (
			<Snackbar
				open={this.state.isOpen}
				anchorOrigin={{ horizontal: "right", vertical: "top" }}>
				<Alert
					severity={this.props.severity}
					variant="filled"
					onClose={this.closeToast.bind(this)}
					className="align-items-center"
					action={
						<IconButton
							aria-label="cancel"
							color="inherit"
							size="small"
							onClick={this.closeToast.bind(this)}>
							<CancelIcon sx={{
								width: "1.7rem",
								height: "1.7rem"
							}} />
						</IconButton>
					}>
					{
						this.props.title &&
						<AlertTitle>{this.props.title}</AlertTitle>
					}
					{
						this.props.message
					}
				</Alert>
			</Snackbar>
		);
	}
}

export default Toaster;