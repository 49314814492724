import { CssBaseline } from '@mui/material';
import { createTheme, PaletteOptions, ThemeProvider } from '@mui/material/styles';

import React from 'react';

import '@fontsource/dm-sans/100.css';
import '@fontsource/dm-sans/200.css';
import '@fontsource/dm-sans/300.css';
import '@fontsource/dm-sans/400.css';
import '@fontsource/dm-sans/500.css';
import '@fontsource/dm-sans/600.css';
import '@fontsource/dm-sans/700.css';
import '@fontsource/dm-sans/800.css';
import '@fontsource/dm-sans/900.css';

import './LandingTheme.css';
import DefaultAlertStyles from './alerts/DefaultAlertStyles';
import DefaultButtonStyles from './buttons/DefaultButtonStyles';
import DefaultDividerStyles from './dividers/DefaultDividerStyles';
import DefaultDrawerStyles from './drawers/DefaultDrawerStyles';
import DefaultListItemStyles from './lists/DefaultListItemStyles';
import { LandingThemePalette } from './LandingThemePalette';

interface LandingThemeProps {
	children?: React.ReactNode;
}

interface LandingThemeState {
}

class LandingTheme extends React.Component<LandingThemeProps, LandingThemeState> {
	constructor(props: LandingThemeProps) {
		super(props);

		this.state = {
		}
	}

	public static CurrentTheme = createTheme({
		typography: {
			"fontFamily": "DM Sans",
			"fontSize": 14,
			"fontWeightLight": 300,
			"fontWeightRegular": 400,
			"fontWeightMedium": 500,
			"fontWeightBold": 700,
		},
		palette: LandingThemePalette,
		components: {
			...DefaultAlertStyles,
			...DefaultButtonStyles,
			//...DefaultDividerStyles,
			...DefaultDrawerStyles,
			...DefaultListItemStyles
		}
	});

	render(): React.ReactNode {
		return (
			<>
				<ThemeProvider theme={LandingTheme.CurrentTheme}>
					<CssBaseline />
					{this.props.children}
				</ThemeProvider>
			</>
		);
	}
}

export default LandingTheme;