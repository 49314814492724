import React from "react";
import { connect } from "react-redux";
import { Outlet } from "react-router-dom";
import AppRoutes from "../../AppRoutes";
import { Box, Card, CardContent, CardHeader, Container, Grid, Link, Typography } from "@mui/material";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import LandingTheme from "../../../styles/LandingTheme";
import { withRouter, WithRouterProps } from "../../../router";
import AppTheme from "../../../styles/AppTheme";

interface LandingMasterProps extends WithRouterProps, WrappedComponentProps {

}

interface LandingMasterState {

}

class LandingMaster extends React.Component<LandingMasterProps, LandingMasterState> {
	constructor(props: LandingMasterProps) {
		super(props);
	}

	render() {
		return (
			<>
			{this.props.location?.pathname?.includes("/admin") ? 
				<>
					<AppTheme>
						<Box
							sx={{
								display: "flex",
								height: "100vh",
								alignItems: "center",
								justifyContent: "center",
								padding: "2rem"}}>
							<Outlet />
						</Box>
					</AppTheme>
				</> :
				<Box
					sx={{
						background: "#ffffff"
					}}>
					<LandingTheme>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								pt: { xs: 5 },
								pb: { xs: 5 },
								pl: { lg: 10, xl: 20 },
								pr: { lg: 10, xl: 20 }
							}}>
							<Outlet />
						</Box>
						<footer>
							<Container maxWidth={false} sx={{backgroundColor: "#F4F4F4", pt: "60px", pb: "60px", textAlign: "center"}}>
								<Typography sx={{display:{md: "inline"}, pr: {md: "30px" }, pl: {md: "30px"}, pt: {xs: "7px", md: "0px"}, pb: {xs: "7px", md: "0px"}}}>
									© 2024 Company Name. All rights reserved.
								</Typography>
								<Typography sx={{ display:{md: "inline"}, pr: {md: "30px"}, pl: {md: "30px"}, pt: {xs: "7px", md: "0px"}, pb: {xs: "7px", md: "0px"}}}>
									<Link target="_blank" href="https://sportprofittips.com/privacy-policy" color={"#000000"}>
										Privacy Policy
									</Link>
								</Typography>
								<Typography sx={{display:{md: "inline"}, pr: {md: "30px"}, pl: {md: "30px"}, pt: {xs: "7px", md: "0px"}, pb: {xs: "7px", md: "0px"}}}>
									<Link target="_blank" href="https://sportprofittips.com/terms-conditions" color={"#000000"}>
										Terms of Service
									</Link>
								</Typography>
							</Container>
						</footer>
					</LandingTheme>
				</Box>}
			</>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
	};
};

export default withRouter(injectIntl(connect(mapStateToProps, null)(LandingMaster)));