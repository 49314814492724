import { ApplicationUser, AuthResponse } from "../../models/AuthModels";
import * as actionTypes from "./ActionTypes";

export const UserLoggedIn = (data: AuthResponse) => {
	return {
		type: actionTypes.USER_LOGGED_IN,
		data: data
	};
}

export const UserLoggedOut = () => {	
	return {
		type: actionTypes.USER_LOGGED_OUT
	};
}