interface LocaleStringValue {
	[key: string]: string
}

interface LocaleString {
	[key: string]: LocaleStringValue | undefined
}

const strings: LocaleString = {
	"en-us":{
		"labels_dashboard": "Dashboard",
		"labels_campaigns": "Campaigns",
		"labels_users": "Users",
		"labels_users_create": "Create User",
		"labels_contacts": "Contacts",
		"labels_contacts_create": "Create Contact",
		"labels_flows": "Flows",
		"labels_email_templates": "Email Templates",
		"labels_settings": "Settings",
		"labels_username": "Username",
		"labels_email": "Email",
		"labels_phone_number": "Phone Number",
		"labels_first_name": "First Name",
		"labels_middle_name": "Middle Name",
		"labels_last_name": "Last Name",
		"labels_confirm_password": "Confirm Password",
		"labels_login": "Login",
		"labels_logout": "Logout",
		"labels_page_not_found_text": "Ups, 404 page is missing",
		"labels_password": "Password",
		"labels_signin": "Sign In",
		"labels_batch_size": "Batch Size",
		"labels_threshold_interval": "Threshold Interval",
		"labels_name": "Name",
		"labels_description": "Description",
		"labels_subject": "Subject",
		"labels_content": "Content",
		"labels_email_template_create": "Create Email Template",
		"labels_campaigns_create": "Create Campaign",
		"labels_flows_create": "Create Flow",
		"labels_interval": "Interval",
		"labels_insufficient_access_text": "You don't have permissions to access this page",
		"labels_my_profile": "My Profile",
		"labels_email_open_from": "Email Open Count From",
		"labels_email_open_to": "Email Open Count To",
		"labels_tags": "Tags",
		"labels_tags_create": "Create Tag"
	}
}

export default strings;