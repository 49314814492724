import * as actionTypes from "./ActionTypes";

export const ChangeLocale = (locale: string) => ({
	type: actionTypes.CHANGE_LOCALE,
	data: locale
});

export const UpdateAppVersion = (appVersion : string) => {
	return {
		type: actionTypes.APP_VERSION_UPDATED,
		data: appVersion
	};
};

export const ShowGlobalLoader = () => {
	return {
		type: actionTypes.SHOW_GLOBAL_LOADER
	};
}

export const HideGlobalLoader = () => {
	return {
		type: actionTypes.HIDE_GLOBAL_LOADER
	};
}
