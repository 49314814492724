import { Box, CircularProgress, LinearProgress } from "@mui/material";
import React from "react";

export enum LoaderType {
	"Circular",
	"Linear"
}

interface LoaderProps {
	type?: LoaderType
}

interface LoaderState {

}

class Loader extends React.Component<LoaderProps, LoaderState> {
	constructor(props: LoaderProps) {
		super(props);
	}

	render(): React.ReactNode {
		const {type = LoaderType.Circular} = this.props;

		return (
			<>
				{
					type === LoaderType.Circular &&
					<CircularProgress
						sx={{
							position: "absolute",
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%)",
							zIndex: "10000"
						}} />
				}
				{
					type === LoaderType.Linear &&
					<LinearProgress />
				}
			</>
		);
	}
}

export default Loader;