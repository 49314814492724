import { ButtonBase, ButtonBaseProps, SxProps } from "@mui/material";
import React, { PropsWithChildren } from "react";
import * as authActions from "../../../store/actions/AuthActions";
import * as accountActions from "../../../store/actions/AccountActions";
import { ApplicationUser } from "../../../models/AuthModels";
import { connect } from "react-redux";
import { withRouter, WithRouterProps } from "../../../router";

import { Theme } from '@mui/material/styles';
import http from "../../../services/HttpService";

interface LogoutProps extends PropsWithChildren, WithRouterProps {
	sx: SxProps<Theme> | undefined,
	logout: Function,
	setCurrentUser: Function
}

interface LogoutState { }

class Logout extends React.Component<LogoutProps, LogoutState> {
	constructor(props: LogoutProps) {
		super(props);
	}

	logout = (event: any) => {
		if (event) {
			event.preventDefault();
		}

		http.post('/authorization/signOut')
			.then((response) => {})
			.catch((error) => {})
			.finally(() => {
				this.props.logout();
				this.props.setCurrentUser(null);
			});
			
		this.props.navigate("/admin/login");
	};

	render(): React.ReactNode {
		return (
			<ButtonBase
				sx={this.props.sx}
				onClick={this.logout.bind(this)}>
				{this.props.children}
			</ButtonBase>
		);
	}
}

const mapDispatchToProps = (dispatch: any) => {
	return {
		logout: () =>
			dispatch(authActions.UserLoggedOut()),
		setCurrentUser: (user: ApplicationUser) =>
			dispatch(accountActions.SetCurrentUser(user))
	};
};

export default withRouter(connect(null, mapDispatchToProps)(Logout));