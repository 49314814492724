import React from "react";

import { ListItemText, ListItem, List, ListItemButton, ListItemIcon } from '@mui/material';
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { WithRouterProps, withRouter } from "../../../router";
import DashboardIcon from '@mui/icons-material/Dashboard';
import CampaignIcon from '@mui/icons-material/Campaign';
import PeopleIcon from '@mui/icons-material/People';
import ContactsIcon from '@mui/icons-material/Contacts';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import EmailIcon from '@mui/icons-material/Email';
import SettingsIcon from '@mui/icons-material/Settings';
import { ApplicationUser } from "../../../models/AuthModels";
import { AppThemePalette } from "../../../styles/AppThemePalette";
import { RoleModel } from "../../../models/RoleModel";
import { appConfig } from "../../../AppConfig";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

interface AdminPortalSideNavigationProps extends WithRouterProps {
	currentUser?: ApplicationUser,
	currentLocation: string,
	drawerWidth: number
}

interface AdminPortalSideNavigationState {
}

class AdminPortalSideNavigation extends React.Component<AdminPortalSideNavigationProps, AdminPortalSideNavigationState> {
	private roles: Array<String>;

	constructor(props: AdminPortalSideNavigationProps) {
		super(props)

		this.roles = this.props.currentUser?.roles ?? [];
		this.state = {
			showTeamsDropdown: false,
			showRoutingDropdown: false
		}
	}

	handleClick(section: string) {
		this.props.navigate(section);
	}

	render() {
		return (
			<>
				<List
					component="nav"
					sx={{
						'.MuiListItemIcon-root': {
							color: AppThemePalette.primary.contrastText,
						},
						// selected and (selected + hover) states
						'&& .Mui-selected, && .Mui-selected:hover': {
							bgcolor: AppThemePalette.secondary.main,
						},
						// hover states
						'& .MuiListItemButton-root:hover': {
							bgcolor: `${AppThemePalette.secondary.main}80`,
						},
					}}>
					<ListItem
						disablePadding
						onClick={() => this.handleClick("/admin")}>
						<ListItemButton
							selected={this.props.currentLocation === "/admin"}>
							<ListItemIcon>
								<DashboardIcon />
							</ListItemIcon>
							<ListItemText sx={{display: { xs: 'none', sm: 'none', md: 'block' }}} primary={<FormattedMessage id="labels_dashboard" />} />
						</ListItemButton>
					</ListItem>
					{this.roles.includes(appConfig.roles.SUPER_ADMIN) || this.roles.includes(appConfig.roles.ADMIN) ? <ListItem
						disablePadding
						onClick={() => this.handleClick("/admin/users")}>
						<ListItemButton
							selected={this.props.currentLocation.includes("/admin/users")}>
							<ListItemIcon>
								<PeopleIcon />
							</ListItemIcon>
							<ListItemText sx={{display: { xs: 'none', sm: 'none', md: 'block' }}} primary={<FormattedMessage id="labels_users" />} />
						</ListItemButton>
					</ListItem> : <></>}
					<ListItem
						disablePadding
						onClick={() => this.handleClick("/admin/contacts")}>
						<ListItemButton
							selected={this.props.currentLocation.includes("/admin/contacts")}>
							<ListItemIcon>
								<ContactsIcon />
							</ListItemIcon>
							<ListItemText sx={{display: { xs: 'none', sm: 'none', md: 'block' }}} primary={<FormattedMessage id="labels_contacts" />} />
						</ListItemButton>
					</ListItem>
					<ListItem
						disablePadding
						onClick={() => this.handleClick("/admin/campaigns")}>
						<ListItemButton
							selected={this.props.currentLocation.includes("/admin/campaigns")}>
							<ListItemIcon>
								<CampaignIcon />
							</ListItemIcon>
							<ListItemText sx={{display: { xs: 'none', sm: 'none', md: 'block' }}} primary={<FormattedMessage id="labels_campaigns" />} />
						</ListItemButton>
					</ListItem>
					<ListItem
						disablePadding
						onClick={() => this.handleClick("/admin/flows")}>
						<ListItemButton
							selected={this.props.currentLocation.includes("/admin/flows")}>
							<ListItemIcon>
								<AccountTreeIcon />
							</ListItemIcon>
							<ListItemText sx={{display: { xs: 'none', sm: 'none', md: 'block' }}} primary={<FormattedMessage id="labels_flows" />} />
						</ListItemButton>
					</ListItem>
					<ListItem
						disablePadding
						onClick={() => this.handleClick("/admin/tags")}>
						<ListItemButton
							selected={this.props.currentLocation.includes("/admin/tags")}>
							<ListItemIcon>
								<LocalOfferIcon/>
							</ListItemIcon>
							<ListItemText sx={{display: { xs: 'none', sm: 'none', md: 'block' }}} primary={<FormattedMessage id="labels_tags" />} />
						</ListItemButton>
					</ListItem>
					<ListItem
						disablePadding
						onClick={() => this.handleClick("/admin/email-templates")}>
						<ListItemButton
							selected={this.props.currentLocation.includes("/admin/email-templates")}>
							<ListItemIcon>
								<EmailIcon />
							</ListItemIcon>
							<ListItemText sx={{display: { xs: 'none', sm: 'none', md: 'block' }}} primary={<FormattedMessage id="labels_email_templates" />} />
						</ListItemButton>
					</ListItem>
					<ListItem
						disablePadding
						onClick={() => this.handleClick("/admin/settings")}>
						<ListItemButton
							selected={this.props.currentLocation === "/admin/settings"}>
							<ListItemIcon>
								<SettingsIcon />
							</ListItemIcon>
							<ListItemText sx={{display: { xs: 'none', sm: 'none', md: 'block' }}} primary={<FormattedMessage id="labels_settings" />} />
						</ListItemButton>
					</ListItem>
				</List>
			</>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		currentUser: state.account.currentUser,
		appVersion: state.app.version
	};
};

export default withRouter(connect(mapStateToProps, null)(AdminPortalSideNavigation));