import React, { ChangeEvent } from "react";
import { FormattedMessage, WrappedComponentProps, injectIntl } from "react-intl";
import { WithRouterProps, withRouter } from "../../../../router";
import { connect } from "react-redux";
import { Box, Button, Container, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, OutlinedInput, Paper, Select, SelectChangeEvent, Switch, TextField } from "@mui/material";
import http from "../../../../services/HttpService";
import Toaster from "../../../controls/toaster/Toaster";
import { ValidationError } from "../../../../models/ValidationErrorModel";
import { CountryModel } from "../../../../models/CountryModels";
import { ContactModel } from "../../../../models/ContactModels";
import { TagModel } from "../../../../models/TagModels";

interface ContactProps extends WrappedComponentProps, WithRouterProps {

}

interface ContactState {
	id?: string;
	email: string;
	emailIsValid?: boolean;
	emailErrorMessage?: string;
	country?: string;
	countryIsValid?: boolean;
	countryErrorMessage?: string;
	isActive: boolean;
	isSubscribed: boolean;
	allCountries?: Array<CountryModel>;
	tagId?: string;
	tags?: Array<TagModel>;
}

class Contact extends React.Component<ContactProps, ContactState> {
	constructor(props: ContactProps) {
		super(props);

		this.state = {
			email: "",
			isActive: true,
			isSubscribed: false
		}

		this.getCountries();
		this.getTags();

		if(this.props.params?.id){
			this.getData();
		}
	}

	async onFieldChangeAsync(event: ChangeEvent<HTMLInputElement>) {
		const { name, value } = event.target;

		if (name === "email") {
			let emailRegex = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);

			const isValid = value.length >= 1 && emailRegex.test(value);

			await this.setState({
				email: value,
				emailIsValid: isValid,
				emailErrorMessage: !isValid ? "Email is required and should contain valid email address" : ""
			});
		}

		if(name === "isactive"){
			await this.setState({
				isActive: !this.state.isActive
			});
		}

        if(name === "issubscribed"){
			await this.setState({
				isSubscribed: !this.state.isSubscribed
			});
		}
	}

	handleSelectChange = (event: SelectChangeEvent<typeof this.state.country>) => {
		const {
			target: { value },
		  } = event;
  
		  this.setState({
			  country: value
		  });
	  };

	  handleSelectTagChange = (event: SelectChangeEvent<typeof this.state.tagId>) => {
		const {
			target: { value },
		  } = event;
  
		  this.setState({
			  tagId: value
		  });
	  };

	handleCancel(event: React.MouseEvent<unknown>) {
		this.props.navigate("/admin/contacts");
	}

	handleSaveChanges(event: React.MouseEvent<unknown>) {
		this.saveChanges();
	}

	saveChanges(){
		const params = {
			email: this.state.email,
			countryId: this.state.country === "" ? undefined : this.state.country,
			tagId: this.state.tagId,
			isActive: this.state.isActive,
			isSubscribed: this.state.isSubscribed
		  };

		if(this.state.id){
			http.put(`/contacts/${this.state.id}`, params)
			.then((response) => {
				this.props.navigate("/admin/contacts");
			}).catch((error) => {
				let errors = error.data?.errors as Array<ValidationError>;
				if(errors){
					errors.map(item => {
						switch(item.property.toLocaleLowerCase()){
							case "email": {
								this.setState({
									emailIsValid: false,
									emailErrorMessage: item.message
								});
								break;
							}
							default: {
								Toaster.Show("error", "Error", item?.message);
								break;
							}
						}
					})
				}
				else{
					Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
				}
			});
		}
		else{
			http.post(`/contacts`, params)
				.then((response) => {
					this.props.navigate("/admin/contacts");
				}).catch((error) => {
					let errors = error.data?.errors as Array<ValidationError>;
					if(errors){
						errors.map(item => {
							switch(item.property.toLocaleLowerCase()){
								case "email": {
									this.setState({
										emailIsValid: false,
										emailErrorMessage: item.message
									});
									break;
								}
								default: {
									Toaster.Show("error", "Error", item?.message);
									break;
								}
							}
						})
					}
					else{
						Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
					}
				});
		}
	}

	getCountries() {
		http.get(`/countries`)
		.then((response) => {
			let responseData = response.data as Array<CountryModel>;
			this.setState({
				allCountries: responseData
			});
		})
		.catch((error) => {
			Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
		});
	}

	getTags() {
		http.get(`/tags/getAll`)
		.then((response) => {
			let responseData = response.data as Array<TagModel>;
			this.setState({
				tags: responseData
			});
		})
		.catch((error) => {
			Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
		});
	 }

	getData(){
		http.get(`/contacts/${this.props.params?.id}`)
			.then((response) => {
				let responseData = response.data as ContactModel;

				this.setState({
					id: responseData.id,
					email: responseData.email,
					country: responseData.countryId ?? "",
					isSubscribed: responseData.isSubscribed,
					isActive: responseData.isActive,
					tagId: responseData.tagId
				});
			}).catch((error) => {
				Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
			});
	}

	render(): React.ReactNode {
		return(
			<Paper>
				<form>
					<FormGroup 
						sx={{
							pt: { xs: 3 },
							//pb: { xs: 3 },
							pr: { xs: 2 },
							pl: { xs: 2 }
					}}>
						<Grid container>
							<Grid item xs={6} sm={6} md={4}>
								<FormControl
									sx={{
										width: "100%",
										pr: { xs: 2}
									}}>
									<TextField
										required
										fullWidth
										disabled={this.props.params?.id != undefined}
										name="email"
										value={this.state.email}
										inputProps={{
											form: {
											  autoComplete: 'off',
											},
										  }}
										label={this.props.intl.formatMessage({ id: "labels_email" })}
										error={this.state.emailIsValid === false}
										onChange={this.onFieldChangeAsync.bind(this)}
										onBlur={(event) => this.onFieldChangeAsync(event as ChangeEvent<HTMLInputElement>)} 
										helperText={this.state.emailErrorMessage} />
								</FormControl>
							</Grid>
							<Grid item xs={6} sm={6} md={4}>
                                <FormControl
									sx={{
										pr: { xs: 2, sm: 2 },
										minWidth: "100%"
									}}>
									<InputLabel id="select-country-label">Country</InputLabel>
									<Select
										labelId="select-country-label"
										input={<OutlinedInput id="select-country" label="Country" />}
										value={this.state.country ?? ''}
										label="Country"
										onChange={this.handleSelectChange}>
										<MenuItem><em>None</em></MenuItem>
										{this.state?.allCountries?.map((item) => {
											return (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>);
										})}
									</Select>
								</FormControl>
							</Grid>
                            <Grid item xs={0} sm={0} md={4}>
							<FormControl
									sx={{
										//pr: { xs: 3, sm: 3 },
										minWidth: "100%"
									}}>
									<InputLabel id="select-tag-label">Tag</InputLabel>
									<Select
										labelId="select-tag-label"
										input={<OutlinedInput id="select-tag" label="Tag" />}
										value={this.state.tagId ?? ''}
										label="Tag"
										onChange={this.handleSelectTagChange}>
										<MenuItem><em>None</em></MenuItem>
										{this.state?.tags?.map((item) => {
											return (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>);
										})}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</FormGroup>
					<FormGroup 
						sx={{
							pt: { xs: 2 },
							//pb: { xs: 3 },
							pr: { xs: 2 },
							pl: { xs: 2 }
						}}>
						<Grid container>
							<Grid item xs={6} sm={6} md={4}>
								<FormControl
									sx={{
										width: "100%",
										pr: { xs: 2}
									}}>
									<FormControlLabel 
										control={
											<Switch 
												name="isactive" 
												onChange={this.onFieldChangeAsync.bind(this)} 
												checked={this.state.isActive } />} 
										label="Is Active?" />
								</FormControl>
							</Grid>
							<Grid item xs={6} sm={6} md={4}>
								<FormControl
									sx={{
										width: "100%",
										//pr: { xs: 2}
									}}>
									<FormControlLabel 
										control={
											<Switch 
												name="issubscribed" 
												onChange={this.onFieldChangeAsync.bind(this)} 
												checked={this.state.isSubscribed } />} 
										label="Is Subscribed?" />
								</FormControl>
							</Grid>
							<Grid item xs={0} sm={0} md={4} />
						</Grid>
					</FormGroup>
					<FormGroup 
						sx={{
							pt: { xs: 2 },
							pb: { xs: 3 },
							//pr: { xs: 2 },
							//pl: { xs: 2 }
						}}>
						<Grid container>
							<Grid item lg={9} md={8} sm={7} xs={6} />
							<Grid item lg={3} md={4} sm={5} xs={6} >
								<Box sx={{display: 'flex', alignItems: 'center', mr: 2}}>
									<Button
										sx={{
											px: 2,
											mr: 1,
											ml: "auto"
										}}
										onClick={this.handleCancel.bind(this)}
										variant="outlined">
										Cancel
									</Button>
									<Button
										sx={{
											px: 2,
										}}
										onClick={this.handleSaveChanges.bind(this)}
										variant="contained">
										Save
									</Button>
								</Box>
							</Grid>
						</Grid>
					</FormGroup>
				</form>
			</Paper>
		);
	}
}

const mapDispatchToProps = (dispatch: any) => {
	return {
	};
};

export default withRouter(injectIntl(connect(null, mapDispatchToProps)(Contact)));