import { Lock, LockOpen, TextFields } from "@mui/icons-material";
import { Box, Button, Stack, Typography } from "@mui/material";
import type { EditorOptions } from "@tiptap/core";
import { useCallback, useRef, useState } from "react";
import {
  LinkBubbleMenu,
  MenuButton,
  RichTextEditor,
  RichTextReadOnly,
  TableBubbleMenu,
  insertImages,
  type RichTextEditorRef,
} from "mui-tiptap";
import EditorMenuControls, { FileData, getFiles } from "./EditorMenuControls";
import useExtensions from "./useExtensions";
import React from "react";
import DOMPurify from "dompurify";
import { DOMParser as ProseMirrorDOMParser } from 'prosemirror-model';

interface EditorProps{
    rteRef: React.RefObject<RichTextEditorRef>
}

function fileListToImageFiles(fileList: FileList): File[] {
  // You may want to use a package like attr-accept
  // (https://www.npmjs.com/package/attr-accept) to restrict to certain file
  // types.
  return Array.from(fileList).filter((file) => {
    const mimeType = (file.type || "").toLowerCase();
    return mimeType.startsWith("image/");
  });
}

function isHTML(str: string) {
  const div = document.createElement('div');
  div.innerHTML = str.trim();
  let isHtml = div.children.length > 0;
  return isHtml;
}

const Editor : React.FC<EditorProps> = (props) => {
  const extensions = useExtensions({
    placeholder: "Content...",
  });
  //const rteRef = useRef<RichTextEditorRef>(null);
  const [showMenuBar, setShowMenuBar] = useState(true);

  const handleNewImageFiles = useCallback(
    async (files: File[], insertPosition?: number) => {
      if (!props.rteRef.current?.editor) {
        return;
      }

      // For the sake of a demo, we don't have a server to upload the files to,
      // so we'll instead convert each one to a local "temporary" object URL.
      // This will not persist properly in a production setting. You should
      // instead upload the image files to your server, or perhaps convert the
      // images to bas64 if you would like to encode the image data directly
      // into the editor content, though that can make the editor content very
      // large. You will probably want to use the same upload function here as
      // for the MenuButtonImageUpload `onUploadFiles` prop.
      
      // const attributesForImageFiles = files.map(async (file, index) => ({
      //   src: (await toBase64(file)), //URL.createObjectURL(file),
      //   alt: file.name,
      // }));

      const attributesForImageFiles = await getFiles(files);
      
      insertImages({
        images: attributesForImageFiles as Array<FileData>,
        editor: props.rteRef.current.editor,
        position: insertPosition,
      });
    },
    [],
  );

  // Allow for dropping images into the editor
  const handleDrop: NonNullable<EditorOptions["editorProps"]["handleDrop"]> =
    useCallback(
      (view, event, _slice, _moved) => {
        if (!(event instanceof DragEvent) || !event.dataTransfer) {
          return false;
        }

        const imageFiles = fileListToImageFiles(event.dataTransfer.files);
        if (imageFiles.length > 0) {
          const insertPosition = view.posAtCoords({
            left: event.clientX,
            top: event.clientY,
          })?.pos;

          handleNewImageFiles(imageFiles, insertPosition);

          // Return true to treat the event as handled. We call preventDefault
          // ourselves for good measure.
          event.preventDefault();
          return true;
        }

        return false;
      },
      [handleNewImageFiles],
    );

  // Allow for pasting images
  const handlePaste: NonNullable<EditorOptions["editorProps"]["handlePaste"]> =
    useCallback(
      (_view, event, _slice) => {
        if (!event.clipboardData) {
          return false;
        }

        const { schema } = _view.state;

        const pastedImageFiles = fileListToImageFiles(
          event.clipboardData.files,
        );
        if (pastedImageFiles.length > 0) {
          handleNewImageFiles(pastedImageFiles);
          // Return true to mark the paste event as handled. This can for
          // instance prevent redundant copies of the same image showing up,
          // like if you right-click and copy an image from within the editor
          // (in which case it will be added to the clipboard both as a file and
          // as HTML, which Tiptap would otherwise separately parse.)
          return true;
        }

        // let html = event.clipboardData.getData('text/html');
        // let text = event.clipboardData.getData('text/plain');
        
        // if(html || isHTML(text)){
        //   //const sanitizedHTML = DOMPurify.sanitize(html && html != "" ? html : text);
        //   const sanitizedHTML = html && html != "" ? html : text;

        //   // Use DOMParser to convert the sanitized HTML into a document
        //   // const parser = new DOMParser();
        //   // const doc = parser.parseFromString(sanitizedHTML, 'text/html');

        //   // Convert the document into a Tiptap-compatible Fragment
        //   //const fragment = _view.state.schema.nodes.content.parseHTML(doc.body);

        //   // Create a transaction to replace the current selection with the parsed content
        //   //const transaction = view.state.tr.replaceSelection(fragment);
        //   //view.dispatch(transaction);

        // const parser = new DOMParser();
        // const doc = parser.parseFromString(sanitizedHTML, 'text/html');
        // const fragment = ProseMirrorDOMParser.fromSchema(schema).parse(doc);
        // console.log(fragment);
        // const transaction = _view.state.tr.replaceSelectionWith(fragment);
        // _view.dispatch(transaction);
          
        //   return true;
        // }

        return false;
      },
      [handleNewImageFiles],
    );

  const [submittedContent, setSubmittedContent] = useState("");

  return (
    <>
      <Box
        sx={{
          // An example of how editor styles can be overridden. In this case,
          // setting where the scroll anchors to when jumping to headings. The
          // scroll margin isn't built in since it will likely vary depending on
          // where the editor itself is rendered (e.g. if there's a sticky nav
          // bar on your site).
          "& .ProseMirror": {
            "& h1, & h2, & h3, & h4, & h5, & h6": {
              scrollMarginTop: showMenuBar ? 50 : 0,
            },
          },
        }}
      >
        <RichTextEditor
          ref={props.rteRef}
          extensions={extensions}
          //content={props.content}
          editorProps={{
            handleDrop: handleDrop,
            handlePaste: handlePaste,
          }}
          renderControls={() => <EditorMenuControls />}
          RichTextFieldProps={{
            // The "outlined" variant is the default (shown here only as
            // example), but can be changed to "standard" to remove the outlined
            // field border from the editor
            variant: "outlined",
            MenuBarProps: {
              hide: !showMenuBar,
            },
          }}
        >
          {() => (
            <>
              <LinkBubbleMenu />
              <TableBubbleMenu />
            </>
          )}
        </RichTextEditor>
      </Box>
    </>
  );
}

export default Editor;