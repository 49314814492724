import { CssBaseline } from '@mui/material';
import { createTheme, PaletteOptions, ThemeProvider } from '@mui/material/styles';

import React from 'react';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import './AppTheme.css';
import DefaultAlertStyles from './alerts/DefaultAlertStyles';
import DefaultButtonStyles from './buttons/DefaultButtonStyles';
import DefaultDividerStyles from './dividers/DefaultDividerStyles';
import DefaultDrawerStyles from './drawers/DefaultDrawerStyles';
import DefaultListItemStyles from './lists/DefaultListItemStyles';
import { AppThemePalette } from './AppThemePalette';

interface AppThemeProps {
	children?: React.ReactNode;
}

interface AppThemeState {
}

class AppTheme extends React.Component<AppThemeProps, AppThemeState> {
	constructor(props: AppThemeProps) {
		super(props);

		this.state = {
		}
	}

	public static CurrentTheme = createTheme({
		typography: {
			"fontFamily": "Roboto",
			"fontSize": 14,
			"fontWeightLight": 300,
			"fontWeightRegular": 400,
			"fontWeightMedium": 500,
			"fontWeightBold": 600,
		},
		palette: AppThemePalette,
		components: {
			...DefaultAlertStyles,
			...DefaultButtonStyles,
			//...DefaultDividerStyles,
			...DefaultDrawerStyles,
			...DefaultListItemStyles
		}
	});

	render(): React.ReactNode {
		return (
			<>
				<ThemeProvider theme={AppTheme.CurrentTheme}>
					<CssBaseline />
					{this.props.children}
				</ThemeProvider>
			</>
		);
	}
}

export default AppTheme;