import { Button, ButtonProps, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom/client';
import AppTheme from '../../../styles/AppTheme';
import styled from '@emotion/styled';

const Input = styled('input')({
  display: 'none',
});

interface UploadDialogButton {
	text: string,
	props?: ButtonProps
}

export interface UploadDialogCancelButton extends UploadDialogButton {
	position: 'left' | 'right'
}

interface UploadDialogProps {
	title?: string,
	message?: string,
	handleFileUpload: {(event: React.MouseEvent<unknown>, file?: File) : void},
	uploadButton: UploadDialogButton,
	cancelButton: UploadDialogCancelButton
}

interface UploadDialogState {
	isOpen: boolean,
	titleColor: string,
    selectedFile?: File
}

const defaultCancelButton = {
	position: "left",
	text: "Cancel",
	props: {
		variant: "outlined"
	}
} as UploadDialogCancelButton;

const defaultUploadButton = {
	position: "left",
	text: "Upload",
	props: {
		variant: "contained"
	}
} as UploadDialogButton;

let uploadDialogRoot = undefined as ReactDOM.Root | undefined;

class UploadDialog extends React.Component<UploadDialogProps, UploadDialogState> {

	constructor(props: UploadDialogProps) {
		super(props);

		this.state = {
			isOpen: true,
			titleColor: ""
		};
	}

	static Show(
		title: string,
		handleFileUpload: {(event: React.MouseEvent<unknown>, file?: File) : void},
		uploadButton: UploadDialogButton = defaultUploadButton,
		cancelButton: UploadDialogCancelButton = defaultCancelButton) {

		const containerElement = document.createElement('div');
		containerElement.id = "upload-modal-wrapper";
		document.body.appendChild(containerElement);

		uploadDialogRoot = ReactDOM.createRoot(containerElement as HTMLElement);

		uploadDialogRoot.render(
			<AppTheme>
				<UploadDialog
					title={title}
					handleFileUpload={handleFileUpload}
					uploadButton={uploadButton}
					cancelButton={cancelButton} />
			</AppTheme>
		);
	}

	componentDidMount(): void {
		let titleColor = AppTheme.CurrentTheme.palette.primary.main;

		this.setState({
			titleColor: titleColor
		});
	}

	closeDialog() {
		this.setState({
			isOpen: false
		});

		setTimeout(() => {
			let wrapper = document.getElementById("upload-modal-wrapper");
			if (wrapper) {
				wrapper.remove();
			}

			uploadDialogRoot?.unmount();
		}, 500);
	}

    handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        
        if(event.target?.files){
            const file = event.target.files[0];
            this.setState({
                selectedFile: event.target.files[0]
            });
        }
    }

	render() {
		return (
			<Dialog
				open={this.state.isOpen}>
				{
					this.props.title &&
					<DialogTitle
						sx={{
							color: this.state.titleColor
						}}>
						{this.props.title}
					</DialogTitle>
				}
				<DialogContent>
                    <TextField 
                        disabled 
                        variant="standard"
                        placeholder="File name"
                        value={this.state.selectedFile?.name ?? ""}/>
                    <label>
                        <Input
                            accept=".csv"
                            id="file-upload"
                            type="file"
                            onChange={this.handleFileChange}
                        />
                        <Button component="span">
                            Browse
                        </Button>
                    </label>
				</DialogContent>
				<DialogActions>
					{
						this.props.cancelButton.position === "left" &&
						<Button
							{...this.props.cancelButton.props}
							onClick={(e) => {
								if (this.props.cancelButton.props?.onClick) {
									this.props.cancelButton.props.onClick(e);
								}
								this.closeDialog();
							}}>
							{
								this.props.cancelButton.text
							}
						</Button>
					}

					{
						<Button
							disabled={this.state.selectedFile === undefined}
							{...this.props.uploadButton.props}
							onClick={(e) => {
								this.props.handleFileUpload(e, this.state.selectedFile);
								this.closeDialog();
							}}>
							{
								this.props.uploadButton.text
							}
						</Button>
					}

					{
						this.props.cancelButton.position === "right" &&
						<Button
							{...this.props.cancelButton.props}
							onClick={(e) => {
								if (this.props.cancelButton.props?.onClick) {
									this.props.cancelButton.props.onClick(e);
								}
								this.closeDialog();
							}}
						>
							{
								this.props.cancelButton.text
							}
						</Button>
					}
				</DialogActions>
			</Dialog>
		);
	}
}

export default UploadDialog;