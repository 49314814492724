import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { Button, FormGroup, Grid, Input } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import InputBase from '@mui/material/InputBase';
import AddIcon from '@mui/icons-material/Add';
import FileUploadIcon from '@mui/icons-material/FileUpload';

type Order = 'asc' | 'desc';

export interface BaseData {
  id: string;
  cells: Array<JSX.Element>;
}

export interface HeadCell {
  disablePadding: boolean;
  id:  string;//keyof BaseData;
  label: string;
  numeric: boolean;
  orderEnabled: boolean;
  order?: Order;
}

interface CustomTableProps {
	data: Array<BaseData>;
  cells: Array<HeadCell>;
  recordsPerPage: Array<number>;
  recordPerPage: number;
  page: number;
  records: number;
  filters?: Array<JSX.Element>;
  handleRequestSort: {(event: React.MouseEvent<unknown>, property: string) : void};
  handleChangePage: {(event: unknown, newPage: number) : void};
  handleChangeRowsPerPage: {(event: React.ChangeEvent<HTMLInputElement>) : void };
  onFieldChangeAsync: {(event: React.ChangeEvent<HTMLInputElement>) : void};
  handleSearch: {(event: unknown) : void};
  handleAdd?: {(event: unknown) : void};
  handleUpload?: {(event: unknown) : void};
}

interface CustomTableState {

}

interface EnhancedTableToolbarProps {
  filters?: Array<JSX.Element>;
  handleSearch: {(event: unknown) : void};
  handleAdd?: {(event: unknown) : void};
  handleUpload?: {(event: unknown) : void};
  onFieldChangeAsync: {(event: React.ChangeEvent<HTMLInputElement>) : void};
}

interface EnhancedTableProps {
  headCell: HeadCell[];
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
}

function EnhancedTableToolbar(this: any, props: EnhancedTableToolbarProps) {
  const [advancedFilter, setAdvancedFilter] = React.useState(false);
  const advancedFilterChange = (event: unknown) => {
    setAdvancedFilter(!advancedFilter);
  };

  return (
    <>
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 2, sm: 2 },
        pt: { sm: 3 },
        pb: { sm: 3 }
      }}
    >
      <Paper
        component="form"
        elevation={3}
        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search"
          name="search"
          inputProps={{ 'aria-label': 'search text' }}
          onChange={props.onFieldChangeAsync.bind(this)}
        />
        <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={props.handleSearch}>
          <SearchIcon />
        </IconButton>
      </Paper>
      <Box sx={{ml: "auto"}}>
      {props.handleUpload ?
        <Button
          sx={{
            mr: {md: 2}
          }}
          variant="outlined"
          onClick={props.handleUpload}>
          <FileUploadIcon />
          Upload
        </Button> :
        <></>}
        {props.handleAdd ?
        <Button
          sx={{
            mr: {md: 2}
          }}
          variant="contained"
          onClick={props.handleAdd}>
          <AddIcon />
          Add
        </Button> :
        <></>}
      {props.filters && props.filters.length > 0 ?
        <Tooltip title="Advanced filter" >
          <IconButton onClick={advancedFilterChange}>
            <FilterListIcon />
          </IconButton>
        </Tooltip> :
      <></>
      }
      </Box>
    </Toolbar>
    {advancedFilter && props.filters && props.filters.length > 0 ? 
    <Box>
      {props.filters}
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 2, sm: 2 },
          pb: { sm: 3 }
        }}>
        <Button
          sx={{
            ml: "auto"
          }}
          size='medium'
          variant='outlined'
          onClick={props.handleSearch}>
          <SearchIcon />
          Find
        </Button>
      </Toolbar>
    </Box> : <></>
    }
    </>
  );
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { headCell, onRequestSort } =
    props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {props.headCell.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={headCell.order ? headCell.order : false}
          >
            {headCell.orderEnabled ? 
            (<TableSortLabel
              active={headCell.order ? true : false}
              direction={headCell.order ? headCell.order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {headCell.order ? (
                <Box component="span" sx={visuallyHidden}>
                  {headCell.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>) : 
            <>{headCell.label}</>}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

class CustomTable extends React.Component<CustomTableProps, CustomTableState> {
	constructor(props: CustomTableProps) {
		super(props);
	}

  render(): React.ReactNode {
		return (<>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <EnhancedTableToolbar 
            filters={this.props.filters}
            onFieldChangeAsync={this.props.onFieldChangeAsync}
            handleSearch={this.props.handleSearch}
            handleAdd={this.props.handleAdd}
            handleUpload={this.props.handleUpload}/>
        </Paper>
      </Box>
			<Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <TableContainer>
            <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'medium'} >
              <EnhancedTableHead
                headCell={this.props.cells}
                onRequestSort={this.props.handleRequestSort}
              />
              <TableBody>
                {this.props.data.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      key={row.id}>
                      {row.cells.map(tableCell =>{
                        return (tableCell);
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={this.props.recordsPerPage}
            component="div"
            count={this.props.records}
            rowsPerPage={this.props.recordPerPage}
            page={!this.props.records || this.props.records <= 0 ? 0 : this.props.page}
            onPageChange={this.props.handleChangePage}
            onRowsPerPageChange={this.props.handleChangeRowsPerPage}
        />
        </Paper>
      </Box>
      </>
		);
	}
}

export default CustomTable;