import * as actionTypes from "../actions/ActionTypes";
import LocalStorageService from "../../services/LocalStorageService";
import { AuthResponse } from "../../models/AuthModels";

const initialState = {
	isUserLoggedIn: false
};

export default function reducer(state = initialState, action: any) {
	switch (action.type) {
		case actionTypes.USER_LOGGED_IN:
			const data = action.data as AuthResponse;
			LocalStorageService.setTokens(data.accessToken, data.refreshToken);

			return { ...state, isUserLoggedIn: true };
		case actionTypes.USER_LOGGED_OUT:
			LocalStorageService.clearTokens();
			return { ...state, isUserLoggedIn: false };		
		default:
			return state;
	}
}
