import React, { Component, RefObject } from 'react';
import PersonIcon from '@mui/icons-material/Person';

import * as authActions from "../../../store/actions/AuthActions";
import * as accountActions from "../../../store/actions/AccountActions";
import { connect } from "react-redux";

import { injectIntl, FormattedMessage, WrappedComponentProps } from 'react-intl';
import { Typography, Button, Menu, Tooltip, MenuItem, Divider, Box } from '@mui/material';
import AppTheme from '../../../styles/AppTheme';
import { ApplicationUser } from '../../../models/AuthModels';
import Logout from '../../controls/logout/Logout';
import MyProfile from '../../controls/profile/MyProfile';

interface AdminPortalTopNavigationProps extends WrappedComponentProps {
	logout: Function,
	setCurrentUser: Function,
	routeName: string,
	currentUser?: ApplicationUser,
}
interface AdminPortalTopNavigationState {
	showAccountMenu: boolean,
}

class AdminPortalTopNavigation extends Component<AdminPortalTopNavigationProps, AdminPortalTopNavigationState> {
	accountMenuRef: RefObject<any>;

	constructor(props: AdminPortalTopNavigationProps) {
		super(props);
		this.state = {
			showAccountMenu: false
		};

		this.accountMenuRef = React.createRef();
		this.logout = this.logout.bind(this);
	}

	logout = (event: any) => {
		if (event) {
			event.preventDefault();
		}

		this.props.logout();
		this.props.setCurrentUser(null);

		const baseUrl = window.location.protocol + "//" + window.location.host;

		window.location.href = baseUrl;
	};

	handleHelperMenuClose() {
		this.setState(prevState => ({
			...prevState,
			showAccountMenu: false
		}))
	}
	render() {
		return (
			<>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						width: "100%"
					}}>
					<Typography variant="h6" sx={{ flexGrow: 1 }}>
						{this.props.routeName}
					</Typography>
					<Box>
						<Tooltip
							title={this.props.intl.formatMessage({ id: "labels_settings" })}
							ref={this.accountMenuRef}
							sx={{
								display: "flex",
								alignItems: "center"
							}}>
							<Button
								size="small"
								color='secondary'
								onClick={() => this.setState({ showAccountMenu: !this.state.showAccountMenu })}>
								<PersonIcon />
							</Button>
						</Tooltip>
						<Menu
							open={this.state.showAccountMenu}
							anchorEl={this.accountMenuRef.current}
							onClose={this.handleHelperMenuClose.bind(this)}
							onClick={this.handleHelperMenuClose.bind(this)}>
							<MenuItem
								disabled={true}
								sx={{
									"&.Mui-disabled": {
										opacity: 1
									},
									padding: "0.7rem",
								}}>
								{
									this.props.currentUser?.username
								}
							</MenuItem>
							<MenuItem
								disabled={true}
								sx={{
									"&.Mui-disabled": {
										opacity: 1
									},
									marginLeft: '0px',
									paddingLeft: '0px',
									paddingRight: '0px'
								}}>
								<Divider sx={{ width: "100%" }} />
							</MenuItem>
							<MenuItem sx={{
									color: AppTheme.CurrentTheme.palette.error.main,
									padding: 0
								}}>
								<MyProfile sx={{
										padding: "0.7rem",
										width: "100%",
										justifyContent: "left"
									}}>
									<FormattedMessage id="labels_my_profile" />
									
								</MyProfile>
							</MenuItem>
							<MenuItem
								sx={{
									color: AppTheme.CurrentTheme.palette.error.main,
									padding: 0
								}}>
								<Logout
									sx={{
										padding: "0.7rem",
										width: "100%",
										justifyContent: "left"
									}}>
									<FormattedMessage id="labels_logout" />
								</Logout>
							</MenuItem>
						</Menu>
					</Box>
				</Box>
			</>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		currentUser: state.account.currentUser as ApplicationUser
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		logout: () =>
			dispatch(authActions.UserLoggedOut()),
		setCurrentUser: (user: ApplicationUser) =>
			dispatch(accountActions.SetCurrentUser(user))
	};
};
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AdminPortalTopNavigation));