import axios from "axios";
import LocalStorageService from "./LocalStorageService";
import {
	UserLoggedIn,
	UserLoggedOut
} from "../store/actions/AuthActions";

import { store } from "../index";
import Toaster from "../components/controls/toaster/Toaster";
import history from "../History";
import qs from "qs";

const customParamsSerializer = (params: any) => {
	return qs.stringify(params, {
	  //encode: false,
	  allowDots: true,
	  //arrayFormat: 'brackets',
	  //format: 'RFC1738'
	});
  };

const http = axios.create({
	//baseURL: `${window.location.protocol}//${window.location.host}/api`,
	baseURL: process.env.REACT_APP_API_BASE_URL,
	paramsSerializer: customParamsSerializer,
	headers: {
		"Content-Type": "application/json",
		"Accept": "application/json"
	}
});

//define request interceptor
http.interceptors.request.use(
	config => {
		const accessToken = LocalStorageService.getAccessToken();
		if (accessToken) {
			config.headers["Authorization"] = `Bearer ${accessToken}`;
		}

		const locale = LocalStorageService.getLocalization();
		if (locale) {
			config.headers["Accept-Language"] = locale;
		}

		return config;
	},
	error => {
		Promise.reject(error);
	}
);

//define response interceptor
http.interceptors.response.use(
	response => {
		return response;
	},
	function (error) {
		const responseStatus = error.response?.status ?? 500;
		const originalRequest = error.config;
		const originalRequestUrl = error.config?.url ?? "";

		if (responseStatus === 404) {
			return Promise.reject({
				response: {
					status: 404,
					data: {}
				}
			});
		}

		if (responseStatus === 401 && !originalRequest._retry && !originalRequestUrl.includes("/authorization/refreshToken")) {
			originalRequest.retry = true;
			const refreshToken = LocalStorageService.getRefreshToken();

			if (refreshToken) {
				return http.post("/authorization/refreshToken", {refreshToken: refreshToken})
					.then(response => {
						if (response.status === 201 || response.status === 200) {
							store.dispatch(UserLoggedIn(response.data));

							originalRequest.headers["Authorization"] = `Bearer ${response.data.accessToken}`;

							return axios(originalRequest);
						} else {
							throw new Error("Refresh token failed");
						}
					}).catch(res => {
						store.dispatch(UserLoggedOut());
						history.replace({ pathname: '/admin/login' });
						history.go(0);
						// Empty object prevents Unauthorized message
						return Promise.reject({});
					});
			} else {
				store.dispatch(UserLoggedOut());
				history.replace({ pathname: '/admin/login' });
				history.go(0);
				// Empty object prevents Unauthorized message
				return Promise.reject({});
			}
		} else if (error.response && error.response.status === 403) {
			// store.dispatch(UserLoggedOut());
			// history.replace({ pathname: '/admin/login' });

			// Empty object prevents Unauthorized message
			//return Promise.reject({});
			return Promise.reject({
				response: {
					status: 403,
					data: {}
				}
			});

		} else if (error.response && !error.response.data && error.response.status !== 404) {
			Toaster.Show("error", "", "Oops, something went wrong.");
		}
		else if(responseStatus === 401){
			store.dispatch(UserLoggedOut());
			history.replace({ pathname: '/admin/login' });
			history.go(0);
			// Empty object prevents Unauthorized message
			return Promise.reject({});
		}

		return Promise.reject(error.response);
	}
);

export default http;