import { ApplicationUser } from "../../models/AuthModels";
import * as actionTypes from "../actions/ActionTypes";

const initialState = {
	currentUser: {}
};

const setCurrentUser = (state: any, action: any) => {
	let currentUser = action.data as ApplicationUser;

	return {
		...state,
		currentUser: currentUser
	};
};

export default function reducer(state = initialState, action: any) {
	switch (action.type) {
		case actionTypes.SET_CURRENT_USER:
			return setCurrentUser(state, action);
		default:
			return state;
	}
}