import { FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, Stack, TableCell, TextField, Toolbar } from "@mui/material";
import React from "react";
import http from "../../../../services/HttpService";
import Toaster from "../../../controls/toaster/Toaster";
import CustomTable from "../../../controls/table/CustomTable";
import { HeadCell, BaseData } from "../../../controls/table/CustomTable";
import { SortOrderModel } from "../../../../models/SortOrderModels"
import { red } from '@mui/material/colors';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { connect } from "react-redux";
import { FormattedMessage, WrappedComponentProps, injectIntl } from "react-intl";
import { WithRouterProps, withRouter } from "../../../../router";
import ConfirmDialog from "../../../dialogs/confirm_dialog/ConfirmDialog";
import { ApplicationUser } from "../../../../models/AuthModels";
import { appConfig } from "../../../../AppConfig";
import { TagModel } from "../../../../models/TagModels";

interface TagsListProps extends WrappedComponentProps, WithRouterProps {
	isUserLoggedIn?: boolean,
	currentUser?: ApplicationUser
}

interface TagsListState {
	page: number;
	records: number;
	recordPerPage: number;
	data: Array<BaseData>;
	cells: Array<HeadCell>;
    fieldsOrder?: Array<SortOrderModel>;
	search?: string;
    name?: string;
    isSystem?: boolean;
}

class TagsList extends React.Component<TagsListProps, TagsListState> {
	private roles: Array<String>;
	private hasAccess: boolean;

	constructor(props: TagsListProps) {
		super(props);

		this.roles = this.props.currentUser?.roles ?? [];
		this.hasAccess = (this.props.isUserLoggedIn && (this.roles.includes(appConfig.roles.SUPER_ADMIN) || this.roles.includes(appConfig.roles.ADMIN))) ?? false;
		
		this.state = {
			page: 0,
			records: 0,
			recordPerPage: 10,
			data: [],
			cells: [
				{disablePadding: false, id: "name", label: "Name", numeric: false, orderEnabled: true},
				{disablePadding: false, id: "issystem", label: "Is System?", numeric: false, orderEnabled: true},
				{disablePadding: false, id: "actions", label: "Actions", numeric: false, orderEnabled: false}],
		}

		this.getData();
	}

	componentDidMount() {
		
    }

	showConfirmDialog(id: string) {
		if(this.hasAccess){
			ConfirmDialog.Show(
				"danger",
				"Delete Tag",
				"Are you shure that you want to delete this tag? The operation can not be reverted!",
				[
					{
						text: "Delete",
						props: {
							variant: "contained",
							color: "error",
							onClick: (event) => this.handleDelete(event, id)
						}
					}
				]
			);
		}
	}

	 onFieldChangeAsync = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;

		switch(name) {
			case "search": {
				this.setState({
					search: value
				});
				break;
			}
            case "name": {
				this.setState({
					name: value
				});
				break;
			}
			default:
				break;
		}
	};

	 handleRequestSort = async (event: React.MouseEvent<unknown>, property: string,
	 ) => {
		let fieldsOrder = this.state.fieldsOrder;
		let cells = this.state.cells;

		cells.map((item, index) => {
			if(item.id === property){
				item.order = (item.order ?  (item.order=== "asc" ? "desc" : undefined) : "asc");
			}
		});

		if(fieldsOrder && fieldsOrder.length > 0){
			let field = fieldsOrder.find((item) => {
				return item.name === property;
			});

			if(field){
				if(field.order === "desc"){
					fieldsOrder = fieldsOrder.filter((item) => {
						return item.name !== property && item.order !== "desc";
					});
				}
				else{
					fieldsOrder.map((item, index) => {
						if(item.name === property){
							item.order = (item.order === "asc" ? "desc" : "asc")
						}
					});
				}
			}
			else {
				fieldsOrder.push({name: property, order: "asc"});
			}
		}
		else {
			fieldsOrder = [{name: property, order: "asc"}];
		}

		await this.setState({
			fieldsOrder: fieldsOrder,
			cells: cells
		});

		this.getData();
	 };
   
	handleChangePage = async (event: unknown, newPage: number) => {
	   await this.setState({
			page: newPage
		});

		this.getData();
	};

	handleSearch = (event: unknown) => {
		this.getData();
	};

	handleAdd = (event: unknown) => {
		this.props.navigate("/admin/tags/create");
	};

    handleEdit = (event: unknown, id: string) => {
        this.props.navigate(`/admin/tags/${id}`, {state: {id: id}});
    };

    handleDelete = (event: unknown, id: string) => {
        if(this.hasAccess){
            http.delete(`/tags/${id}`).then((response) => {
                this.getData();
            }).catch((error) => {
                Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
            });
        }
    };
   
	 handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement>) => {
		let recordsPerPage = parseInt(event.target.value, 10);

		await this.setState({
			recordPerPage: recordsPerPage
		});

		this.getData();
	 };

	  
    handleIsSystemSelectChange = (event: SelectChangeEvent<typeof this.state.isSystem>) => {
        const {
            target: { value },
        } = event;

        this.setState({
            isSystem: typeof value === 'string' ? undefined : value
        });
    };

	 getData(){
		const params = {
			page: this.state.page <= 0 ? 1 : this.state.page+1,
			records: this.state.recordPerPage,
			fieldsOrder: this.state.fieldsOrder,
			search: this.state.search,
            name: this.state.name,
			isSystem: this.state.isSystem
		  };

		http.get(`/tags`, 
			{params})
			.then((response) => {
				let responseData = response.data.data as Array<TagModel>;

				this.setState({
					page: response.data.page-1,
					records: response.data.records,
					data: responseData?.map((row, index) => {
						return(
							({id: row.id, cells: [
								<TableCell key={'name-'+row.id} component="th" scope="row">{row.name}</TableCell>,
								<TableCell key={'is-system-'+row.id}>{row.isSystem ? "Yes" : "No"}</TableCell>,
								<TableCell key={'actions-'+row.id}>
									<Stack alignItems="center" direction="row" gap={1}>
										<Button 
                                            disabled={row.isSystem}
											size="small" 
											variant="outlined"
											onClick={event => this.handleEdit(event, row.id)}
											>
											<Stack alignItems="center" direction="row" gap={1}>
												<EditIcon />
											</Stack>
										</Button>
										<Button 
										disabled={!this.hasAccess || row.isSystem}
										sx={{color: red[500]}} 
										size="small" 
										variant="outlined" 
										onClick={event => this.showConfirmDialog(row.id)}>
											<Stack alignItems="center" direction="row" gap={1}>
												<DeleteIcon />
											</Stack>
										</Button>
									</Stack>
								</TableCell>
							]})
						);
					})
				});
			}).catch((error) => {
				Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
			});
	 }

	getTableFilters = () => {
		return [
			<Toolbar key="filter-group-1"
				sx={{
					pl: { xs: 2 },
					pr: { xs: 2 },
					pb: { xs: 2 }
				}}>
				<FormControl 
					sx={{
						pr: { xs: 2, sm: 2 },
						minWidth: 1/3
					}}>
					<TextField
						fullWidth
						size='medium'
						name="name"
						label="Name"
						onChange={this.onFieldChangeAsync.bind(this)} />
				</FormControl>
                <FormControl 
					sx={{
						pr: { xs: 2, sm: 2 },
						minWidth: 1/3
					}}>
					<InputLabel id="select-is-system-label">Is System?</InputLabel>
					<Select
						sx={{
							width: "100%"
						}}
						labelId="select-is-system-label"
						input={<OutlinedInput id="select-is-system" label="Is System" />}
						value={this.state.isSystem ?? ''}
						label="Is System?"
						onChange={this.handleIsSystemSelectChange}>
						<MenuItem><em>All</em></MenuItem>
						<MenuItem value={true as any}>Yes</MenuItem>
						<MenuItem value={false as any}>No</MenuItem>
					</Select>
				</FormControl>
			</Toolbar>
			];
	}

	render(): React.ReactNode {
		return(
			<>
				<CustomTable
					data={this.state.data}
					cells={this.state.cells}
					recordsPerPage={[10, 25, 50]}
					recordPerPage={this.state.recordPerPage}
					records={this.state.records}
					page={this.state.page}
					onFieldChangeAsync={this.onFieldChangeAsync}
					filters={this.getTableFilters()}
					handleChangePage={this.handleChangePage}
					handleChangeRowsPerPage={this.handleChangeRowsPerPage}
					handleRequestSort={this.handleRequestSort}
					handleSearch={this.handleSearch}
					handleAdd={this.handleAdd}
				/>
			</>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		isUserLoggedIn: state.auth.isUserLoggedIn,
		currentUser: state.account.currentUser
	};
};

export default withRouter(injectIntl(connect(mapStateToProps, null)(TagsList)));