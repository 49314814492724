import { Components } from "@mui/material"
import { Theme } from "@mui/system"
import { AppThemePalette } from "../AppThemePalette"

export default {
	MuiDrawer: {
		styleOverrides: {
			paper: {
				background: AppThemePalette.primary.dark,
				color: AppThemePalette.primary.contrastText
			}
		}
	}
} as Components<Omit<Theme, "components">>